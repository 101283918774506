import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  CardContent, IconButton, styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomeButton from "../../../components/src/CustomeButton.web";
// Customizable Area End

import PaymentSuccessFullController, {
  Props,
} from "./PaymentSuccessFullController";

export default class PaymentSuccessfull extends PaymentSuccessFullController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <Box

          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000',
            color: '#fff',
            width: "100%",
            flexDirection: 'column',
            fontSize: 'clamp(10px, 16px, 2vw)',
            gap: '15px',
            padding: '10px 5px',
            boxSizing: 'border-box'
          }}
        >
          <IconButton style={{ padding: 0 }}>
            <CheckCircleIcon style={{ width: 'clamp(70px, 148px, 15vw)', fontSize: 80, color: '#fff', height: 'clamp(70px, 148px, 15vw)' }} />
          </IconButton>
          <Typography style={{ fontSize: '2.8em', fontWeight: 700, fontFamily: 'inter', }}>
            Subscribed successfully
          </Typography>
          <Typography style={{ whiteSpace: 'pre-line', fontWeight: 400, fontSize: '1em', textAlign: 'center' }}>
            You have successfully subscribed. View, use, and manage your subscription under 'My Subscriptions'.
          </Typography>
          <CustomeButton onclicked={this.goToViewMySubscriptionPage} style={{ ...webStyle.buttonCommonStyle, color: '#fff', border: '1px solid #FFFFFF', marginTop: 'clamp(0px, 30px, 2vw)' }} primary={false} text="View My Subscriptions" />

          <CustomeButton onclicked={this.goBackToLandingPage} style={{ ...webStyle.buttonCommonStyle, border: '1px solid #F4CD46', color: '#fff', marginBottom: 'clamp(0px, 30px, 2vw)' }} primary={true} text="Go to home" />

          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom style={{ fontSize: '1em', fontWeight: 600 }}>
                Plan Details
              </Typography>

              <Typography style={{ marginTop: '30px' }} variant="body2" >
                <span style={{ fontWeight: 600 }}>Plan</span>: {`${this.state.subscriptionData?.name}`}
              </Typography>
              <Typography style={{ marginTop: '5px' }} variant="body2" >
                <span style={{ fontWeight: 600 }}>Price</span>: &#163;{`${this.state.subscriptionData?.price}/`}
                {`month`}
              </Typography>
              <Typography style={{ marginTop: '5px' }} variant="body2" >
                <span style={{ fontWeight: 600 }}>What do you get</span>: {`${this.state.subscriptionData?.number_of_units !==0 ? this.state.subscriptionData?.number_of_units : 'Unlimited'} ${this.state.subscriptionData?.name_of_units} per ${this.state.subscriptionData?.per}`}
              </Typography>
              <Typography style={{ marginTop: '5px' }} variant="body2" >
                <span style={{ fontWeight: 600 }}>Duration</span>: 
                {this.state.subscriptionData?.duration == 0 ? 'Unlimited' : `${this.state.subscriptionData?.duration} months`}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Card = styled(Box)({
  backgroundColor: '#000',
  color: 'white',
  border: '1px solid #262525',
  width: '373px',
  height: '256px',
  boxSizing: 'border-box' as const,
  "@media(max-width: 875px)": {
    width: '350px',
    height: '225px',
  },
  "@media(max-width: 825px)": {
    width: '300px',
    height: '220px',
  },
  "@media(max-width: 700px)": {
    width: '280px',
    height: '210px',
  }
});

const webStyle = {
  buttonCommonStyle: {
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '225px',
    height: '56px',
    textTransform: 'capitalize' as 'capitalize',
    color: '#fff',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '53px',
    background: '#000',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1em',
  },
}

// Customizable Area End
