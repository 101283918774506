Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Subscriptions";
exports.labelBodyText = "customform4 Body";

exports.btnExampleTitle = "CLICK ME";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.getAllSubscriptionListApiEndPoint = "bx_block_custom_user_subs/subscriptions";
exports.removeArchiveSubscriptionApiEndPoint = "bx_block_custom_user_subs/remove_subscription_from_archive?id=";
exports.archiveSubscriptionApiEndPoint = "bx_block_custom_user_subs/archive_subscription?id="
exports.editSubscriptionApiEndPoint = "bx_block_custom_user_subs/subscriptions?id="
exports.activeSubscriptionApiEndPoint = "bx_block_custom_user_subs/get_active_subscriptions"
exports.achivedSubscriptionApiEndPoint="bx_block_custom_user_subs/get_archive_subscriptions"
exports.createSubscriptionApiEndPoint = "bx_block_custom_user_subs/subscriptions"
exports.getSelectBranchApiEndPoint = "bx_block_branch/branches?business_id="
exports.getBusinessUserDataApiEndPoint = "account_block/business";
exports.cancelMembershipText1 = "We’re sorry to see you go"
exports.cancelMembershipText2 = "When you cancel your membership we’ll close your account and delete all your data."
exports.cancelMembershipText3 = "Before you proceed, please note:"
exports.cancelMembershipText4 = "Your subscribers will no longer be able to use their subscriptions at your business if you no longer have a membership with Go QR. This may mean you need to make alternative arrangements in order to honour any goods or services already paid for by your subscribers."
exports.cancelMembershipDialogTitle = "Cancel Membership"
exports.cancelMembershipDialogContent = "Are you sure you want to cancel your membership? This will cancel all your customer subscriptions also. You can’t undo this action."
exports.discriptionTooltipText = "The description is intended to provide additional information about the plan."
exports.priceTooltipText = "The price of the subscription per month e.g. 1234.56"
exports.durationTooltipText = " The length of the subscription"
exports.unitsTooltipText = "The number of units a subscriber will get per the selected unit frequency"
exports.perTooltipText = "The frequency for the selected number of units e.g. 3 units per week "
exports.unitsLabelTooltipText = " Give the number of units a custom name e.g. classes, sessions"
exports.pausesTooltipText = "The number of times a subscriber will be able to pause their subscription"
// Customizable Area End