import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  DialogContentText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled, Dialog, DialogContent, DialogActions, Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { CSSProperties } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomeButton from "../../../components/src/CustomeButton.web";
import CustomDialog from "../../../components/src/CustomDailog.web";

const buttonStyle = (isActive: boolean): CSSProperties => ({
  backgroundColor: isActive ? 'white' : 'transparent',
  color: isActive ? 'black' : 'white',
});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '50px 0',
        background: '#212226'
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontFamily: 'inter',
        padding: '8px',
        backgroundColor: '#212226',
        opacity: 1,
        maxWidth: 300,
      },
      arrow: {
        color: '#212226',
      }
    }
  }
});

const BtnGroup = styled(Box)({
  paddingLeft: '8vw',
  paddingTop: '30px',
  paddingRight: '15px',
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
  '@media (max-width: 500px)': {
    justifyContent: 'center',
    paddingTop: '15px',
  }
})

const Arrows = styled(Box)({
  paddingLeft: '8vw',
  paddingTop: '10px',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 500px)': {

  }
});

const Card = styled(Box)({
  color: 'white',
  border: '1px solid #262525',
  textAlign: 'center' as 'center',
  width: '376px',
  padding: '15px',
  '@media (max-width: 800px)': {
    width: '300px',
  }
})

const CustomButton = styled(Button)({
  border: '0.5px solid white',
  fontSize: '1em',
  fontWeight: 600,
  fontFamily: 'Inter',
  width: '225px',
  height: '56px',
  borderRadius: '53px',
  textTransform: 'capitalize' as 'capitalize',
  '@media (max-width: 800px)': {
    width: '180px',
    height: '45px',
  }
});

const ButtonContinue = {
  padding: '4px 8px',
  width: '180px',
  height: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center' as 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: "clamp(14px, 3vw, 16px)",
  color: '#fff',
  fontWeight: 600,
  letterSpacing: '-0.408px',
  borderRadius: '70px',
  border: '1px solid #F4CD46',
  textTransform: 'capitalize' as 'capitalize',
  '@media (max-width: 601px)': {
    width: '130px',
    height: '40px',
  }
};

const ButtonClose = {
  fontSize: '1em',
  color: '#fff',
  width: '180px',
  height: '56px',
  borderRadius: '70px',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  textAlign: 'center' as 'center',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  letterSpacing: '-0.408px',
  textTransform: 'capitalize' as 'capitalize',
  border: '1px solid #fff',

  '@media (max-width: 601px)': {
    width: '130px',
    height: '40px',
  }
};
const SubsDetails = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  padding: '30px',
  boxSizing: 'border-box',
  margin: 'auto',
  width: '90%',

  '@media (max-width: 600px)': {
    justifyContent: 'center',
    padding: '15px 30px',
  },
  '@media (max-width: 1001px)': {
    // gridTemplateColumns: '1fr',
    // width: '400px'
  }
})
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DialogActionsWrap = styled(DialogActions)({
    justifyContent: 'center',
    gap: '24px',
    flexWrap: 'wrap',
    '@media (max-width: 601px)': {
      gap: '12px',
      padding: '0 10px',
    }
  });

  ButtonContinue = styled(Button)({
    padding: '4px 8px',
    width: '180px',
    height: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: "1em",
    color: '#fff',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    textTransform: 'capitalize' as 'capitalize',
    '@media (max-width: 601px)': {
      width: '130px',
      height: '40px',
    }
  });
  ButtonClose = styled(Button)({
    fontSize: '1em',
    color: '#fff',
    width: '180px',
    height: '56px',
    borderRadius: '70px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #fff',
    "&:hover": {
      border: "1.5px solid #F4CD46",
    },
    '@media (max-width: 601px)': {
      width: '130px',
      height: '40px',
    }
  });

  returnBillData = (viewDetailsData: any) => {
    return (
      <ThemeProvider theme={theme}>
            <Box sx={webStyle.viewDetailsContainerStyle}>
              <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: 'clamp(1rem, 4rem, 10vw)' }}>
                <ArrowBackIcon 
                  onClick={this.handleBackToList} 
                  style={webStyle.arrowBackIconStyle} 
                  data-test-id="arrow-back-icon-btn" 
                />
                <Box style={{ width: 'clamp(30px, 70px, 10vw)', height: 'clamp(30px, 70px, 10vw)', borderRadius: '50%', overflow: 'hidden' }}>
                  <img src={viewDetailsData.business_logo} alt="" style={{ width: '100%', height: '100%', }} />
                </Box>
                <Typography style={webStyle.headingStyle}>
                  {viewDetailsData.business_name}
                </Typography>
              </Box>
              {this.unpaidTextUi(webStyle.unpaidTextBoxStyle, webStyle.unpaidTextStyle)}
              <Box style={this.state.subStatus === "unpaid" ? { marginTop: '175px' } : {}}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginBottom: 'clamp(15px, 30px, 7vw)' }}>
                  <Box>
                    <Typography style={webStyle.planTypeStyle}>{viewDetailsData.subscription.name}</Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.planPriceStyle}>
                      {`£${viewDetailsData.subscription.price}/`}
                      <span style={{ color: '#fff', fontSize: '1em', fontWeight: 200, }}>month</span>
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', flexDirection: 'column', gap: '10px', }}>
                  <Box>
                    <Typography style={webStyle.planDescHeading}>About this Plan</Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.planDetailsStyle}>
                      {viewDetailsData.subscription.description}
                    </Typography>
                  </Box>
                </Box >
                <Box sx={webStyle.subscriptionDescriptionContainerStyle}>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>What you get</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {`${viewDetailsData.subscription.number_of_units!=0 ? viewDetailsData.subscription.number_of_units: 'Unlimited'} ${viewDetailsData.subscription.name_of_units} per ${viewDetailsData.subscription.per}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>Duration</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {this.getDuration(viewDetailsData.subscription.duration)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>Pauses Allowed</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {viewDetailsData.subscription.pauses_allowed}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>Valid Branches</Typography>
                    <Tooltip title={this.getBranchNameList()}
                      data-test-id="branch-name-tooltip"
                      arrow
                      PopperProps={{
                        popperOptions: {
                          modifiers: {
                            offset: {
                              offset: '20, -15',
                            },
                          },
                        },
                      }}
                      disableFocusListener
                      style={webStyle.tooltipStyle}>
                      <Typography style={{ ...webStyle.descHeadingValueStyle, cursor: 'pointer' }} data-test-id="valid-branch-data">
                        {viewDetailsData.subscription.valid_branches}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.purchaseDateText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {this.formatDate(viewDetailsData.purchase_date)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.nextBillingText }</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {this.getNextBillingDate()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.unitsRemainingText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {viewDetailsData.subscription.number_of_units!=0 ? viewDetailsData.user_subscription_details.unites_remaining : 'Unlimited'}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={webStyle.transactionHistoryContainerStyle}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography style={{ color: '#fff', fontSize: '1.75em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.72px' }}
                    >
                     {configJSON.transactionHistoryText}
                    </Typography>
                    {this.state.expandTransactionHistory ?
                      <ArrowDropUpIcon
                        onClick={this.handleTranscationHistoryExpand}
                        style={{ color: '#fff', fontSize: '2.2em', cursor: 'pointer', }}
                        data-test-id="arrow-drop-up-icon"
                      /> :
                      <ArrowDropDownIcon
                        style={{ cursor: 'pointer', color: '#fff', fontSize: '2.2em', }}
                        onClick={this.handleTranscationHistoryExpand}
                        data-test-id="arrow-drop-down-icon"
                      />}
                  </Box>
                  <Box>
                    <TableContainer style={{ maxHeight: '450px', display: 'flex', justifyContent: 'center', }}>
                      <Table stickyHeader aria-label="sticky table" size="small" style={{ fontSize: 'clamp(10px, 16px, 2vw)' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={webStyle.tableHeaderCellStyle}>Transaction</TableCell>
                            <TableCell style={webStyle.tableHeaderCellStyle}>Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.transactionData.map((row: any, index: number) => {
                            if (!this.state.expandTransactionHistory && index >= 3) {
                              return null;
                            }
                            return (
                              <TableRow key={index}>
                                <TableCell scope="row" style={webStyle.tableBodyCellStyle} >
                                  {`${row.transaction_type}`}
                                </TableCell>
                                <TableCell style={webStyle.tableBodyCellStyle}>{this.convertDateFormat(row.date)}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
                {this.renderButtonUi(webStyle.buttonBoxStyle)}
              </Box >
            </Box >


            <Dialog open={this.state.PauseCheck} style={{ backdropFilter: 'blur(5px)' }} aria-labelledby="form-dialog-title" >
              <Typography id="form-dialogue-title" style={webStyle.dialogTitleStyle}>
                <ErrorOutlineOutlinedIcon style={{ color: '#E82929', fontSize: '1em', marginRight: '10px', }} />
                Pause Subscription
              </Typography>
              <DialogContent>
                <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                 {configJSON.dialogContextText1} {this.settingDAte()} {configJSON.dialogContextText2}
                </DialogContentText>
                <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 500 }}>
                 {configJSON.dialogContextText3}
                </DialogContentText>
              </DialogContent>
              <this.DialogActionsWrap>
                <CustomeButton 
                  text="Cancel" 
                  onclicked={this.PauseCancelBtn} 
                  data-test-id="login-dialog-close-btn" 
                  primary={false} style={ButtonClose} 
                />
                <CustomeButton 
                  text="Confirm" 
                  onclicked={this.pauseSubscriptionApiCall} 
                  data-test-id="login-dialog-continue-btn" 
                  primary={true} 
                  style={ButtonContinue} 
                />
              </this.DialogActionsWrap>
            </Dialog>

            <Dialog open={this.state.PauseConfirm} aria-labelledby="form-dialog-title">
              <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
                <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: '1em' }} />
                Paused Successfully
              </Typography>
              <DialogContent>
                <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300, whiteSpace: 'pre-wrap' }}>
                 {configJSON.dialogContextText4} {this.settingDAte()}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center', gap: '24px' }}>
                <CustomeButton 
                  text="Close" 
                  onclicked={this.gotoSub} 
                  data-test-id="pause-confirm-dialog-close-btn" 
                  primary={true} 
                  style={{ ...webStyle.dialogButtonStyle, background: '#212226' }} 
                />
              </DialogActions>
            </Dialog>

          </ThemeProvider >
    )
  }
  // Customizable Area End

  render() {
    const { activeButton, viewDetailsData } = this.state;
    return (
      // Customizable Area Start
      <>
        {this.state.loading && <SpinnerLoader />}
        {!this.state.openDetails &&
          <Box style={{ fontSize: 'clamp(10px, 16px, 2vw)' }} >
            <Arrows>
              <ArrowBackIcon
                id="backBtn"
                onClick={this.LandingPage}
                style={{ ...webStyle.arrowBackIconStyle, position: 'relative', right: '20px', bottom: '5px' }}
                data-test-id="arrow-back-icon-btn"
              />
              <Typography style={webStyle.heading}>My Subscriptions</Typography>
            </Arrows>

            <BtnGroup>
              <CustomButton
                style={buttonStyle(activeButton === 'active')}
                id="acti"
                onClick={() => {
                  this.setActiveButton('active')
                  this.setActiveBtnName('Active')
                }}
              >
                Active
              </CustomButton>
              <CustomButton
                style={buttonStyle(activeButton === 'cancel')}
                id="canc"
                onClick={() => {
                  this.setActiveButton('cancel')
                  this.setActiveBtnName('Cancelled')
                }}
              >
                Cancelled
              </CustomButton>
              <CustomButton
                style={buttonStyle(activeButton === 'pause')}
                id="paus"
                onClick={() => {
                  this.setActiveButton('pause')
                  this.setActiveBtnName('Paused')
                }}
              >
                Paused
              </CustomButton>
            </BtnGroup>
            {this.renderNoDataFoundMessage()}
            <SubsDetails>
              {this.state.SubsciptionData?.map((list: any, i: any) => {
                return (
                  <Card>
                    <Box style={headerStyles}>
                      <Typography style={webStyle.headerTextStyle}>{list.attributes.business_name}</Typography>

                      <Box style={{ width: 'clamp(35px, 50px, 7vw)', height: 'clamp(35px, 50px, 7vw)', borderRadius: '50%', overflow: 'hidden' }}>
                        <img src={list.attributes.business_logo} alt="" style={{ width: '100%', height: '100%', }} />
                      </Box>
                    </Box>
                    <Box style={{ ...headerStyles, marginTop: 'clamp(15px, 30px, 7vw)' }}>
                      <Typography style={{ fontSize: '1.25em', fontWeight: 600, fontFamily: 'inter' }}>
                        {list.attributes.custom_user_subs_subscription.name}
                      </Typography>
                      <Typography style={{ fontSize: '1.5em', fontWeight: 200, fontFamily: 'inter' }}>
                        <span style={{ fontWeight: 500 }}>&#163;{list.attributes.custom_user_subs_subscription.price}</span>/{"month"}
                      </Typography>
                    </Box>
                    <Typography style={{ fontWeight: 500, fontSize: '1em', marginTop: 'clamp(20px, 40px, 7vw)', position: 'relative', top: '10px' }}>
                      {list.attributes.custom_user_subs_subscription.number_of_units !== 0 ? list.attributes.custom_user_subs_subscription.number_of_units : 'Unlimited'} {list.attributes.custom_user_subs_subscription.name_of_units} per {list.attributes.custom_user_subs_subscription.per}
                    </Typography>
                    <Button
                      style={{
                        ...((this.state.ischeck === 'active' && list.attributes.status === 'unpaid') ? chipStyles : Unpaid),
                        textTransform: 'none',
                        pointerEvents: 'none',
                        visibility: this.state.ischeck === 'active' ? 'visible' : 'hidden',
                        marginBottom: '10px',
                        fontSize: '1em',
                        fontWeight: 500
                      }}
                    >
                      {list.attributes.status === 'unpaid' ? 'Unpaid' : `Left for the period : ${list.attributes.custom_user_subs_subscription.number_of_units != 0 ? list.attributes.unites_remaining : 'Unlimited'}`}
                    </Button>
                    {this.renderButtonss(list)}
                  </Card>
                )
              })}
            </SubsDetails>
          </Box >
        }
        {this.state.openDetails &&
          this.returnBillData(viewDetailsData)
        }
        <ThemeProvider theme={theme}>
          <Dialog open={this.state.openCancelDialog} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
              <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', fontSize: '1em' }} />
              Cancel Subscription
            </Typography>
            <DialogContent>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                {`If you choose to cancel, you will retain complete access to the subscription until ${this.getNextBillingDate()}. After this date, you will no longer have access`}
              </DialogContentText>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 500 }}>
              {configJSON.dialogContextText5 }
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px', flexWrap: 'wrap' }}>
              <CustomeButton 
                text="Cancel" 
                onclicked={this.handleCancelDialogCancelBtn} 
                data-test-id="cancel-dialog-cancel-btn" 
                primary={false} 
                style={ButtonClose} 
              />
              <CustomeButton 
                text="Confirm" 
                onclicked={this.handleCancelDialogConfirmBtn} 
                data-test-id="cancel-dialog-confirm-btn" 
                primary={true} 
                style={ButtonContinue} 
              />
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.isSubscriptionCanceled} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
              <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: '1em' }} />
              {configJSON.cancelledDialogTitle}
            </Typography>
            <DialogContent>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                {configJSON.cancelledDialogContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px' }}>
              <CustomeButton 
                text="Close" 
                onclicked={this.handleConfirmationDialogCloseBtn} 
                data-test-id="cancel-dialog-confirm-btn" 
                primary={true} 
                style={{ ...webStyle.dialogButtonStyle, background: '#212226' }} 
              />
            </DialogActions>
          </Dialog>
          <CustomDialog
            isError={true}
            open={this.state.openPauseErrorDialog}
            actions={[
              {
                label: "OK",
                handler: this.handlePauseErrorDialogBtn,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary: true
              },
            ]}
            content={this.state.pauseErrorDialogMsg}
            title="Failed to pause"
          />
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  heading: {
    color: 'white',
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: '2em',
  },
  wholeContent: {
    paddingLeft: '8vw',
    paddingTop: '10px'
  },
  btngrp: {
    paddingLeft: '8vw',
    paddingTop: '30px'
  },
  btnstyle: {
    color: 'white',
    border: '0.5px solid white',
    fontSize: '1em',
    fontWeight: 600,
    fontFamily: 'Inter',
    margin: '17px',
    width: '225px',
    height: '56px',
    borderRadius: '53px',

  },
  cardss: {
    paddingLeft: '10px'
  },
  card: {
    backgroundColor: '#000',
    color: 'white',
    border: '1px solid #262525',
    textAlign: 'center' as const,
    width: '375px',
    height: '295px',
    marginLeft: '10px'
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 700,
    marginTop: '16px',
  },
  premium: {
    fontSize: '1.1em',
    fontWeight: 500,
  },
  description: {
    position: 'relative' as 'relative',
    marginTop: '8px',
    color: 'white',
    marginBottom: '8px',
    display: 'inline',
    marginRight: '300px',
    top: '24.5px'
  },
  units: {
    color: 'white',
    marginLeft: '290px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px 10px',
    padding: '0 10px',
    margin: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '1176px',
    marginTop: '20px',
  },
  headingStyle: {
    fontWeight: 700,
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '2em',
    letterSpacing: '-0.408px',
  },
  planTypeStyle: {
    fontFamily: 'Inter, sans-serif',
    color: '#fff',
    fontSize: '2em',
    fontWeight: 300,
    letterSpacing: '-0.408px',
    textTransform: 'capitalize' as 'capitalize'
  },
  planPriceStyle: {
    color: '#fff',
    fontSize: '2em',
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
  },
  arrowBackIconStyle: {
    fontSize: '2em',
    color: '#FFF',
    cursor: 'pointer',
  },
  planDescHeading: {
    color: '#fff',
    fontSize: '1.5em',
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.72px',
  },
  tooltipStyle: {
    opacity: 1,
    maxWidth: "300px",
    minHeight: "73.2px",
    fontSize: '0.9em',
    fontFamily: 'inter',
    padding: '2px',
    backgroundColor: '#212226',
  },
  descHeadingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
  },
  planDetailsStyle: {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 200,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
  },
  viewDetailsContainerStyle: {
    padding: { xs: "0 20px", md: "0 3rem", lg: "0 5rem", xl: "0 8rem", },
    position: 'relative',
    fontSize: 'clamp(10px, 16px, 2vw)'
  },
  descHeadingValueStyle: {
    color: '#FFF',
    fontSize: '1.5em',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 200,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
  },
  subscriptionDescriptionContainerStyle: {
    display: 'flex',
    backgroundColor: 'transparent',
    padding: { xs: "0", md: "35px 8%", lg: "35px 14%", xl: "35px 8rem", },
    justifyContent: 'center',
    alignItem: 'center',
    gap: '8px 10%',
    flexWrap: 'wrap' as 'wrap',
  },
  transactionHistoryContainerStyle: {
    display: 'flex',
    gap: '2rem',
    backgroundColor: 'transparent',
    padding: { xs: "20px", md: "35px 8%", lg: "35px 14%", xl: "35px 8rem", },
    flexDirection: 'column' as 'column'
  },
  buttonCommonStyle: {
    width: '269px',
    height: '56px',
    color: '#fff',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    textTransform: 'capitalize' as 'capitalize',
    background: '#000'
  },
  dialogTitleStyle: {
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  dialogContentStyle: {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
  },
  dialogButtonStyle: {
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    border: '1px solid #F4CD46',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.25em',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: 'white',
    textTransform: 'capitalize' as 'capitalize'
  },
  tableHeaderCellStyle: {
    background: '#212226',
    border: 0,
    color: '#fff',
    textAlign: 'center' as 'center',
    fontSize: '1em',
    fontWeight: 500,
    letterSpacing: '-0.408px',
  },
  tableBodyCellStyle: {
    background: '#000000',
    border: 0,
    color: '#fff',
    textAlign: 'center' as 'center',
    fontSize: '1em',
    fontWeight: 200,
    letterSpacing: '-0.72px',
  },
  unpaidTextBoxStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 'clamp(70px, 100px, 10vw)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#212226'
  },
  unpaidTextStyle: {
    fontFamily: "Inter",
    fontSize: "3.1em",
    fontWeight: 700,
    textTransform: "uppercase",
    color: '#FA0808'
  },
  buttonBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    gap: '20px',
    flexWrap: 'wrap'
  },
  headerTextStyle: {
    fontFamily: "Inter",
    fontSize: "1.5em",
    fontWeight: 600,
    color: '#fff'
  }

}

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',

};


const avatarStyles = {
  backgroundColor: 'white',
  color: 'black',
};

const chipStyles = {
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: '#FEE2E2',
  color: '#DC2626',
  width: '196px',
  borderRadius: '21px',
  fontFamily: 'inter',
};

const Unpaid = {
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: '#F4CD46',
  color: '#000000',
  width: '258px',
  borderRadius: '23px',
  fontFamily: 'inter',
}
// Customizable Area End
