import React from "react";
// Customizable Area Start
import {
  Container, Button, Grid, Box, Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  Select,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";

import "react-datepicker/dist/react-datepicker.css";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import "./customisableUserProfile.css"
import CustomTextField, { LightTooltip } from "../../../components/src/CoustomTextfield.web";
import TransitionsModal from "../../../components/src/TransitionsModal/TransitionsModal";
import UserContext from "../../../components/src/UserProvider";
import { configJSON } from "./CustomisableUserProfilesController";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { group, image_nob, image_noe } from "./assets";
import CustomeButton from "../../../components/src/CustomeButton.web";



// const maxDate = new Date();
// maxDate.setFullYear(maxDate.getFullYear() - 10);
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PublishIcon from '@material-ui/icons/Publish';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      icon: {
        color: '#000',
        width: '40px',
        height: '40px',
        top: 'calc(50% - 20px)'
      }
    }
  }
});

const editTheme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      icon: {
        color: '#000',
        width: '40px',
        height: '40px',
        top: 'calc(50% - 20px)'
      },
      root: {
        position: 'relative',
        bottom: '7px'
      }
    }
  }
});

const editBSETheme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiFilledInput: {
      root: {
        height: '28px'
      }
    },
    MuiSelect: {
      icon: {
        color: '#000', width: '40px', height: '40px',
        top: 'calc(50% - 20px)'
      },
      root: {
        position: 'relative', bottom: '7px'
      }
    }
  }
});

import CreateNewBusinessController, {
  Props,
  IField
} from "./CreateNewBusinessController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import CustomDialog from "../../../components/src/CustomDailog.web";
import { color } from "react-native-reanimated";

const MyBusinessProfileTitle = styled(Typography)({
  '@media (max-width: 600px)': {
    margin: "20px 0"
  },
});

const SubmitButton = styled(Button)({
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #fff",
  borderRadius: "70px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  marginTop: '15px',
  "&:hover": {
    color: "white",
    backgroundColor: '#F4CD46'
  }
});
const submitButton = {
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #F4CD46",
  borderRadius: "70px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  marginTop: '15px',
}

const CancelButton = {
  display: "flex",
  width: "425px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #fff",
  borderRadius: "70px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "white",
  textTransform: "capitalize",
  marginTop: '15px',
  '&:hover': {
    color: 'black',
    backgroundColor: '#FFFFFF'
  }
};

// Customizable Area End

export default class CreateNewBusiness extends CreateNewBusinessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    CreateNewBusinessController.contextType = UserContext
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { formData, errors, imagePreviewURL, imageURL, isCropping, selectedData, businessData } = this.state;
    const bottomPosition = this.bottomPostion()
    const centerContent = this.centerPostion()
    const widthSize = this.widthSize()
    const heightSize = this.heightSize()
    const lastEntry = this.getLastEntry();

    return (
      <ThemeProvider theme={theme}>
        {
          this.state.largeImageError && <CustomDialog
            isError={true}
            open={this.state.largeImageError}
            actions={[
              {
                label: "OK",
                handler: this.largeImageErrorDialog,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary:false
              },
            ]}
            content={this.state.uploadImageErrorMsg}
            title="Failed to Create Business"
          />
        }
        {
          this.state.ImageError && <CustomDialog
            isError={true}
            open={this.state.ImageError}
            actions={[
              {
                label: "Cancel",
                handler: this.ImageErrorDialog,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary:false
              },
              {
                label: "Reupload",
                handler: this.handleChooseFile,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary:true
              }
            ]}
            content="File is not supported."
            title="Failed to Upload Image"
          />
        }
        {
          this.state.duplicateName && <CustomDialog
            isError={true}
            open={this.state.duplicateName}
            actions={[
              {
                label: "Cancel",
                handler: this.ErrorDialog,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary :true
              }
            ]}
            content=""
            title="This name is already taken."
          />
        }
        {this.state.loading && <SpinnerLoader />}
        {
          selectedData?.attributes ?
            <div>
              {
                this.state.isEdit ?
                  <ThemeProvider theme={editTheme}>
                    <Container maxWidth='lg' id="customisable-business-profile" style={{ padding: '24px', fontSize: 'clamp(10px, 16px, 2vw)' }}>
                      <Typography className="profile-headding">My Business Profile</Typography>
                      <Grid container style={{ ...webStyle.mainContainerStyle, justifyContent: centerContent }}>
                        <Grid item xs={12} sm={12} md={4} lg={3} className="img-container" style={{ ...webStyle.ImgContainerStyle }}>
                          <div style={{ ...webStyle.ImgUploadBackground, position: 'relative', bottom: bottomPosition, width: widthSize, height: heightSize }}>
                            <div style={{ ...webStyle.ImgUpload, width: widthSize, height: heightSize }} >{imagePreviewURL &&
                              <div data-test-id='image-upload-div-data' onClick={(event: any) => this.handleClick(event)} style={{width: '100%', height: '100%'}}>
                                <img
                                  id="profile_image" className="image-priview business_user_logo_img00 business_logo_field00" key={new Date().getTime()}
                                  src={imagePreviewURL} alt="Preview"
                                  style={{ borderRadius: '50%', width: "100%", height: "100%" }} /></div>}
                              <input
                                type="file" 
                                accept="image/*,.heif,.heic"
                                onChange={this.handleImageChange} 
                                id="handleImageChange" 
                                style={{ display: 'none' }} 
                                ref={this.fileInputRef} 
                                data-test-id="editLogoInput" 
                                
                              />

                              {!imagePreviewURL ?
                                <div data-test-id="handlechoosefile" onClick={this.handleChooseFile} style={{ display: 'block', textAlign: 'center' }}>
                                  <button id="handleChooseFile" style={{ border: "none" }}><CloudUploadIcon /></button>
                                  <Typography className="upload-profile-photo" style={webStyle.uploadProfilePhoto}> Upload Business Logo</Typography>
                                </div> : ""}
                              <TransitionsModal handleCropImage={this.handleCropImage} setOpen={this.setOpen} modelComponent={'ImageCrop'} open={isCropping} src={imageURL} />
                            </div> <Popover
                              open={!!this.state.anchorEl} anchorEl={this.state.anchorEl} onClose={this.handleClose}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }} transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <List component="nav"
                                aria-labelledby="nested-list-subheader" style={{ padding: "8px", flexShrink: 0, borderRadius: "8px", width: "192px", height: "82px", display: "flex", flexDirection: "column", justifyContent: "space-evenly", boxSizing: "border-box" }} >
                                <ListItem button style={{ padding: 0, margin: 0, height: "36px" }} onClick={this.handleChooseFile}>
                                  <ListItemIcon style={{ minWidth: "30px" }}><PublishIcon /></ListItemIcon>
                                  <Typography style={{ padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black" }}>Upload New Photo</Typography>
                                </ListItem>
                                <ListItem
                                  button style={{ padding: 0, margin: 0, height: "36px" }} onClick={this.removeProfilePhoto} >
                                  <ListItemIcon style={{ minWidth: "30px" }}><DeleteOutlineIcon /></ListItemIcon><Typography style={{ padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black" }}>Remove Photo</Typography>
                                </ListItem>
                              </List>
                            </Popover>
                          </div>
                        </Grid>
                        <form onSubmit={this.handleEditSubmit}>
                          <Grid container spacing={2} className="form-grid">
                            <Grid item xs={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={{ ...webStyle.heading, marginBottom: '10px' }}>Business name*</Typography>
                              <CustomTextField name="business_name" istrue={true} placeholder=""
                                dataTestId="txtInputBusinessName" value={businessData?.business_name}
                                error={errors.business_name}
                                fullWidth onChange={this.handleInputChange}

                              />
                            </Grid>
                            <Grid item xs={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={{ ...webStyle.heading, margin: '12px 0 10px 0' }}>Address*</Typography>
                              <CustomTextField name="address" istrue={true} placeholder="" dataTestId="txtInputAddress" value={businessData?.address}
                                fullWidth onChange={this.handleInputChange} error={errors.address} />
                            </Grid>
                            <Grid item xs={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={{ ...webStyle.heading, margin: '12px 0 10px 0' }}>Company number</Typography>
                              <CustomTextField name="company_number" istrue={true} error={errors.company_number} placeholder="" dataTestId="txtInputEmail" value={businessData?.company_number}
                                onChange={this.handleInputChange}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={{ ...webStyle.heading, margin: '12px 0 10px 0' }}>Industry*</Typography>
                              <TextField
                                id="standard-select-currency"
                                select
                                style={{
                                  height: '48px !important', width: '100%',
                                  backgroundColor: 'white',
                                }} variant="filled"
                                name="industry" data-test-id="industry-data"
                                fullWidth error={!!errors.industry}
                                // value={businessData?.industry}
                                value={businessData?.industry || ""}
                                size="small" onChange={this.handleInputChange}
                                // data-test-id="selectProps"
                                SelectProps={{
                                  renderValue: (selected: any) => {
                                    if (selected === "industry") { return "industry"; }
                                    return selected;
                                  },
                                }}
                              >
                                {this.getIndustryMenuItem()}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={{ ...webStyle.heading, marginTop: '12px' }}>Business Type*</Typography>
                              <TextField
                                id="standard-select-currency"
                                select style={{
                                  height: '48px !important', width: '100%',
                                  backgroundColor: 'white', marginTop: '10px'
                                }}
                                variant="filled" name="business_type"
                                fullWidth value={businessData?.business_type} size="small"
                                onChange={this.handleInputChange}
                                error={!!errors.business_type}
                                data-test-id="businessType"
                                SelectProps={{
                                  renderValue: (selected: any) => {
                                    if (selected === "business_type") {
                                      return "business_type";
                                    }
                                    return selected;
                                  },
                                }}
                              >
                                {configJSON.businessTypesList?.map((item: { label: string, value: string }, indx: number) => {
                                  return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                                })}
                              </TextField>

                            </Grid>
                            <ThemeProvider theme={editBSETheme}>
                              <Box style={webStyle.numbers_B_S_E_Container}>
                                <Box style={webStyle.dropdownBoxesStyle} >
                                  <img src={image_nob} style={{ maxWidth: '50px', height: '43px', width: '100%' }} />
                                  <Typography style={webStyle.heading}>Number of branches*</Typography>
                                  <TextField
                                    id="standard-select-currency"
                                    select style={{
                                      width: '100%',
                                      backgroundColor: 'white'
                                    }}
                                    data-test-id="nob"
                                    variant="filled" name="number_of_branches"
                                    fullWidth value={businessData?.number_of_branches} error={!!errors.number_of_branches}
                                    size="small" onChange={this.handleInputChange}
                                    SelectProps={{
                                      renderValue: (selected: any) => {
                                        if (selected === "number_of_branches") {
                                          return "number_of_branches";
                                        }
                                        return selected;
                                      },
                                    }}
                                  >
                                    {configJSON.branchesMenuList?.map((item: { label: string, value: string }, indx: number) => {
                                      return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                                    })}
                                  </TextField>
                                </Box>

                                <Box style={webStyle.dropdownBoxesStyle}>
                                  <img src={image_noe} style={{ maxWidth: '50px', height: '43px', width: '100%' }} />
                                  <Typography style={webStyle.heading}>Number of employees*</Typography>
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    style={{
                                      height: '48px !important', width: '100%',
                                      backgroundColor: 'white'
                                    }} variant="filled"
                                    name="number_of_employees" fullWidth error={!!errors.number_of_employees}
                                    value={businessData?.number_of_employees} size="small"
                                    onChange={this.handleInputChange} data-test-id="noe"
                                    SelectProps={{
                                      renderValue: (selected: any) => {
                                        if (selected === "number_of_employees") {
                                          return "number_of_employees";
                                        }
                                        return selected;
                                      },
                                    }}
                                  >
                                    {configJSON.employesNoMenuList?.map((item: { label: string, value: string }, indx: number) => {
                                      return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                                    })}
                                  </TextField>
                                </Box>

                                <Box style={webStyle.dropdownBoxesStyle}>
                                  <img src={group} style={{ maxWidth: '50px', height: '43px', width: '100%' }} />
                                  <Typography style={webStyle.heading}>Expected number of subscribers*</Typography>
                                  <TextField
                                    id="standard-select-currency"
                                    select style={{
                                      height: '48px !important', width: '100%',
                                      backgroundColor: 'white', marginTop: '3px'
                                    }}
                                    variant="filled"
                                    name="expected_number_of_subscribers"
                                    fullWidth error={!!errors.expected_number_of_subscribers}
                                    value={businessData?.expected_number_of_subscribers} size="small"
                                    onChange={this.handleInputChange} data-test-id="enos"
                                    SelectProps={{
                                      renderValue: (selected: any) => {
                                        if (selected === "expected_number_of_subscribers") {
                                          return "expected_number_of_subscribers";
                                        }
                                        return selected;
                                      },
                                    }}
                                  >
                                    {configJSON.subscribersNoMenuList?.map((item: { label: string, value: string }, indx: number) => {
                                      return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                                    })}
                                  </TextField>

                                </Box>
                              </Box>
                            </ThemeProvider>
                          </Grid>
                          <Box className="button-box buttons">
                            <CustomeButton text="Cancel" primary={false} style={CancelButton} onclicked={this.cancelEditProfile} />
                            <CustomeButton text="Save" primary={true} style={submitButton} type="submit" />
                          </Box>
                        </form>
                      </Grid>
                    </Container>
                  </ThemeProvider>
                  :
                  <>
                    <Container maxWidth='lg' id="customisable-business-profile" style={{ padding: '24px', fontSize: 'clamp(10px, 16px, 2vw)' }}>
                      <Typography className="profile-headding">My Business Profile</Typography>
                      <Grid container style={{ ...webStyle.mainContainerStyle, justifyContent: centerContent }}>
                        <Grid item xs={12} sm={12} md={4} lg={3} className="img-container" style={{ ...webStyle.ImgContainerStyle }}> <div style={{ ...webStyle.ImgUploadBackground, position: 'relative', bottom: bottomPosition, width: widthSize, height: heightSize }}>
                          {this.imageRender(webStyle)}
                        </div>
                        </Grid>
                        <form onSubmit={this.handleSubmit}>
                          <Grid container spacing={2} className="form-grid">
                            <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}> <Typography style={webStyle.heading}>Business name*</Typography> <Paper style={webStyle.PaperStyle}>
                              {lastEntry?.attributes?.business_name}</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}> <Typography style={webStyle.profileheading}>Address*</Typography>
                              <Paper style={webStyle.PaperStyle}> {lastEntry?.attributes?.address}</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}><Typography style={webStyle.profileheading}>Company number</Typography><Paper style={webStyle.PaperStyle}>  {lastEntry?.attributes?.company_number} </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={webStyle.profileheading}>Industry*</Typography>
                              <Paper style={webStyle.PaperStyle}>
                                {lastEntry?.attributes?.industry}
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                              <Typography style={{ ...webStyle.profileheading }}>Business Type*</Typography>
                              <Paper style={webStyle.PaperStyle}>
                                {lastEntry?.attributes?.business_type}
                              </Paper>

                            </Grid>
                            <Box style={webStyle.numbers_B_S_E_Container}>
                              <Box style={webStyle.dropdownBoxesStyle} >
                                <img src={image_nob} style={{ maxWidth: '50px', height: '43px', width: '100%' }} />
                                <Typography style={webStyle.heading}>Number of branches*</Typography>
                                <div>
                                  {lastEntry?.attributes?.number_of_branches}
                                </div>
                              </Box>

                              <Box style={webStyle.dropdownBoxesStyle}>
                                <img src={image_noe} style={{ maxWidth: '50px', height: '43px', width: '100%' }} />
                                <Typography style={webStyle.heading}>Number of employees*</Typography>
                                <div>
                                  {lastEntry?.attributes?.number_of_employees}
                                </div>
                              </Box>

                              <Box style={webStyle.dropdownBoxesStyle}>
                                <img src={group} style={{ maxWidth: '50px', height: '43px', width: '100%' }} />
                                <Typography style={webStyle.heading}>Expected number of subscribers*</Typography>
                                <div>
                                  {lastEntry?.attributes?.expected_number_of_subscribers}
                                </div>
                              </Box>
                            </Box>
                          </Grid>
                          <Box className="button-box">
                            <CustomeButton data-test-id="btnSave" onclicked={this.handleEditBusinesnessProfileBtn} style={submitButton} text={"Edit"} primary={true} />

                          </Box>
                        </form>
                      </Grid>
                    </Container>
                  </>
              }
            </div>
            :
            <Container maxWidth='lg' id="customisable-business-profile" style={{ padding: '24px', fontSize: 'clamp(10px, 16px, 2vw)' }}>
              <MyBusinessProfileTitle className="profile-headding">
                Create New Business
              </MyBusinessProfileTitle>
              <Grid container spacing={8} style={{ ...webStyle.mainContainerStyle, justifyContent: centerContent }}>
                <Grid item xs={12} sm={12} md={4} lg={3} className="img-container" style={{ ...webStyle.ImgContainerStyle }}>
                <div style={{ ...webStyle.ImgUploadBackground, position: 'relative', bottom: bottomPosition, width: widthSize, height: heightSize }}>
                <div style={{ ...webStyle.ImgUpload,width: widthSize, height: heightSize, zIndex : 10000}} >             
                {imagePreviewURL && 
                <div data-test-id='image-upload-div' style={{height:'100%' , width:'100%', borderRadius:'50%' , zIndex: 10000}} onClick={(event: any) =>this.handleClick(event)}>
                          <img
                            id="profile_image"
                            key={new Date().getTime()}
                            src={imagePreviewURL}
                            alt="Preview"
                            style={{ width: "100%", height: "100%", borderRadius: '50%' }}
                          />
                        </div>}
                      <input
                        type="file"
                        data-test-id="uploadLogoInput"
                        accept="image/*,.heif,.heic"
                        onChange={this.handleImageChange}
                        id="handleImageChange"
                        style={{ display: 'none' }}
                        ref={this.fileInputRef}
                      />

{
                    !imagePreviewURL ?
                    <div
                    onClick={this.handleChooseFile}
                    style={{ display: 'block', textAlign: 'center' }}
                  >                       
                   <button id="handleChooseFile" style={{ border: "none", marginTop: '25%' }}>
                         <CloudUploadIcon />
                        </button>
                        <Typography className="upload-profile-photo" style={webStyle.uploadProfilePhoto}> Upload Business Logo</Typography>
                      </div> : ""
                  }
                      <TransitionsModal handleCropImage={this.handleCropImage} setOpen={this.setOpen} modelComponent={'ImageCrop'} open={isCropping} src={imageURL} />
                    </div> <Popover
                      id={!!this.state.anchorEl ? 'simple-popover' : undefined}
                      open={!!this.state.anchorEl} anchorEl={this.state.anchorEl} onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'center', horizontal: 'center',
                      }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                    >
                      <List component="nav" aria-labelledby="nested-list-subheader"
                        style={{ padding: "8px", flexShrink: 0, borderRadius: "8px", width: "192px", height: "82px", display: "flex", flexDirection: "column", justifyContent: "space-evenly", boxSizing: "border-box" }} >
                        <ListItem button style={{ padding: 0, margin: 0, height: "36px" }} onClick={this.handleChooseFile}>
                          <ListItemIcon style={{ minWidth: "30px" }}><PublishIcon /></ListItemIcon> <Typography style={{ padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black" }}>Upload New Photo</Typography>
                        </ListItem>
                        <ListItem button style={{ padding: 0, margin: 0, height: "36px" }} onClick={this.removeProfilePhoto}>
                          <ListItemIcon style={{ minWidth: "30px" }} ><DeleteOutlineIcon /> </ListItemIcon> <Typography style={{ padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black" }}>Remove Photo</Typography>
                        </ListItem> </List>
                    </Popover>
                  </div>
                </Grid>
                <form onSubmit={this.handleSubmit} style={{ width: "68%", marginLeft: "30px" }}>
                  <Grid container spacing={4} xs={12} sm={12} style={{ maxWidth: '854px' }}>
                    <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                      <Typography style={{ ...webStyle.heading, marginBottom: "10px" }}>Business name*</Typography>
                      <CustomTextField
                        name="business_name"

                        istrue={true}
                        placeholder=""
                        dataTestId="txtInputBusinessName"
                        value={formData?.business_name}
                        error={errors?.business_name}
                        fullWidth
                        onChange={this.onHandleChange}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                      <Typography style={{ ...webStyle.heading, marginBottom: '10' }}>Address*</Typography>
                      <CustomTextField

                        name="address"
                        istrue={true}
                        placeholder=""
                        dataTestId="txtInputAddress"
                        value={formData?.address}
                        error={errors?.address}
                        fullWidth
                        onChange={this.onHandleChange}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                      <Typography style={{ ...webStyle.heading, marginBottom: '10' }}>Company number</Typography>
                      <CustomTextField

                        name="company_number"
                        istrue={true}
                        placeholder=""
                        dataTestId="txtInputEmail"
                        value={formData?.company_number}
                        error={errors?.company_number}
                        onChange={this.onHandleChange}
                        fullWidth

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                      <Typography style={webStyle.heading}>Industry*</Typography>
                      <LightTooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="bottom-start"
                        open={!!errors.industry}
                        disableFocusListener
                        arrow
                        disableHoverListener
                        disableTouchListener
                        title={<><ErrorOutlineOutlinedIcon className="error-icon" />{errors.industry}</>}
                      >
                        <Select
                          fullWidth
                          // dataTestId="txtInputBusinessType"
                          data-test-id="select-business"
                          labelId="demo-simple-select-required-label"
                          name="industry"
                          onChange={(event: any) => this.onHandleChange(event)}
                          value={formData?.industry}
                          error={!!errors?.industry}
                          style={{ padding: "7px 12px", background: '#fff', width: '100%', height: '48px', marginTop: '10px' }}
                          id="dropdown" >
                          {Array.isArray(this.state.allBranches) && this.state.allBranches.flatMap((option) => {
                            const branchName = option?.attributes?.industry_subcategory;
                            return branchName.map((subcategory:string) => (
                              <MenuItem key={subcategory[0]} value={subcategory[1]}>
                                {subcategory[1]}
                              </MenuItem>
                            ));
                          })}
                        </Select>
                      </LightTooltip>

                    </Grid>
                    <Grid item xs={12} sm={12} style={{padding: 'clamp(5px, 15px, 2vw)'}}>
                      <Typography style={{ ...webStyle.heading }}>Business Type*</Typography>
                      <LightTooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="bottom-start"
                        open={!!errors.business_type}
                        disableFocusListener
                        arrow
                        disableHoverListener
                        disableTouchListener
                        title={<><ErrorOutlineOutlinedIcon className="error-icon" />{errors.business_type}</>}
                      >

                        <Select
                          fullWidth
                          // dataTestId="txtInputBusinessType"
                          data-test-id="select-business-type"
                          labelId="demo-simple-select-required-label"
                          name="business_type"
                          onChange={(event: any) => this.onHandleChange(event)}
                          value={formData?.business_type}
                          error={!!errors?.business_type}
                          style={{ padding: "7px 12px", background: '#fff', width: '100%', height: '48px', marginTop: '10px' }}
                          id="dropdown" >
                          <MenuItem value={"Individual"}>
                            Individual
                          </MenuItem>
                          <MenuItem value={"Company"}>
                            Company
                          </MenuItem>
                          <MenuItem value={"Non-profit"}>
                            Non-profit
                          </MenuItem>
                        </Select>
                      </LightTooltip>
                    </Grid>
                    <Box style={webStyle.numbers_B_S_E_Container}>
                      <Box style={webStyle.dropdownBoxesStyle} >
                        <img src={image_nob}
                          style={{ maxWidth: '50px', height: '43px', width: '100%' }}
                        />
                        <Typography style={webStyle.heading}>Number of branches*</Typography>
                        <LightTooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement="bottom-start"
                          open={!!errors.number_of_branches}
                          disableFocusListener
                          arrow
                          disableHoverListener
                          disableTouchListener
                          title={<><ErrorOutlineOutlinedIcon className="error-icon00" />{errors?.number_of_branches}</>}
                        >
                          <Select
                            labelId="demo-simple-select-required-label"
                            name="number_of_branches"
                            data-test-id="select-number-branches"
                            onChange={(event: any) => this.onHandleChange(event)}
                            value={formData?.number_of_branches}
                            style={{ padding: "2px 12px", background: '#fff', width: '170px', height: '28px' }}
                            id="dropdown" >
                            {configJSON.branchesMenuList?.map((item: { label: string, value: string }, indx: number) => {
                              return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                            })}
                          </Select>
                        </LightTooltip>
                      </Box>
                      <Box style={webStyle.dropdownBoxesStyle}>
                        <img src={image_noe}
                          style={{ maxWidth: '50px', height: '43px', width: '100%' }}
                        />
                        <Typography style={webStyle.heading}>Number of employees*</Typography>
                        <LightTooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement="bottom-start"
                          open={!!errors.number_of_employees}
                          disableFocusListener
                          arrow
                          disableHoverListener
                          disableTouchListener
                          title={<><ErrorOutlineOutlinedIcon className="error-icon00" />{errors?.number_of_employees}</>}
                        >
                          <Select
                            labelId="demo-simple-select-required-label"
                            name="number_of_employees"
                            data-test-id="select-number-employees"
                            onChange={(event: any) => this.onHandleChange(event)}
                            value={formData?.number_of_employees}
                            style={{ padding: "7px 12px", background: '#fff', width: '170px', height: '28px' }}
                            id="dropdown"
                          >
                            {configJSON.employesNoMenuList?.map((item: { label: string, value: string }, indx: number) => {
                              return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                            })}
                          </Select>
                        </LightTooltip>
                      </Box>
                      <Box style={webStyle.dropdownBoxesStyle}>
                        <img src={group}
                          style={{ maxWidth: '50px', height: '43px', width: '100%' }}
                        />
                        <Typography style={webStyle.heading}>Expected number of subscribers*</Typography>
                        <LightTooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement="bottom-start"
                          open={!!errors.expected_number_of_subscribers}
                          disableFocusListener
                          arrow
                          disableHoverListener
                          disableTouchListener
                          title={<><ErrorOutlineOutlinedIcon className="error-icon00" />{errors?.expected_number_of_subscribers}</>}
                        >
                          <Select
                            name="expected_number_of_subscribers"
                            labelId="demo-simple-select-required-label"
                            data-test-id="select-number-subscribers"
                            onChange={(event: any) => this.onHandleChange(event)}
                            value={formData?.expected_number_of_subscribers}
                            style={{ padding: "7px 12px", background: '#fff', width: '170px', height: '28px' }}
                            id="dropdown"
                          >
                            {configJSON.subscribersNoMenuList?.map((item: { label: string, value: string }, indx: number) => {
                              return <MenuItem key={indx} value={item?.value} style={{ width: '170px', }}>{item?.label}</MenuItem>
                            })}
                          </Select>
                        </LightTooltip>
                      </Box>
                    </Box>
                  </Grid>
                  <Box className="button-box">
                    {/* <SubmitButton
                //   className={`button ${this.state.isValid ? 'btn-opacity' : ''}`}
                data-test-id="btnSave"
                variant="contained"
                color="primary"
                // onClick={this.handleSubmit}
                type="submit"
                style={this.state.isValid ? {borderColor:'#F4CD46'} : {}}
              >
                Save
              </SubmitButton> */}
                    <CustomeButton text="Save" type="submit" data-test-id="btnSave" style={submitButton} primary={true} />

                  </Box>
                </form>
              </Grid>
            </Container>
        }
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  profileHeaddingStyle: {
    fontSize: '2em',
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    letterspacing: '-0.408px',
    margin: '45px 0 0 0'
  },
  mainContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    maxWidth: '100%',
    margin: '0'
  },
  ImgContainerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  ImgUploadBackground: {
    flexShrink: 0,
    borderRadius: "50%",
    background: "#EFEFEF",
    marginBottom: "40px",
    // position:'relative' as 'relative', 
    // bottom:'125px',
  },
  ImgUpload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadProfilePhoto: {
    color: "#000",
    textAlign: "center" as 'center',
    fontFamily: "'Inter', sans-serif",
    fontSize: "1em",
    fontWeight: 300,
    letterSpacing: "-0.408px"
  },
  heading: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '1em',
    fontWeight: 300,
    letterSpacing: '-0.408px',
  },
  profileheading: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '1em',
    fontWeight: 300,
    letterSpacing: '-0.408px'
  }
  ,
  numbers_B_S_E_Container: {
    with: "100%",
    color: 'FFF',
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    flexWrap: 'wrap' as 'wrap',
    marginTop: '40px',
    width: '100%'
  },
  dropdownBoxesStyle: {
    minWidth: "260px",
    height: '193px',
    border: "1px solid #FFF",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "22px",
    padding: "30px 15px",
    borderRadius: "5px",
    boxSizing: 'border-box' as 'border-box'
  },
  PaperStyle: {
    padding: '15px',
    height: '9px',
    backgroundColor: '#212226',
    color: 'white',
    marginBottom: '10px',
    paddingBottom: '24px',
    marginTop: '8px'
  }

}

// Customizable Area End
