import React from 'react';

// Customizable Area Start
import { Box, Button, Grid, TextField, Typography, Tooltip, Theme, Fab } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { styled, withStyles } from '@material-ui/core/styles';
import { logo} from './assets';

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    alignItems: 'center',
    display: 'flex',
    gap: "10px",
    justifyContent: 'center',
    color: '#222',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    maxWidth: 'none',
    height: '40px',
    lineHeight: 'normal',
    letterSpacing: '-0.408px',

  },
  arrow: {
    fontSize: 20,
    // left: 0,
    color: "white",
    "&::before": {
      backgroundColor: "white",
    }
  }
}))(Tooltip);
// Customizable Area End

import OTPInputAuthController, {
  Props
} from "./OTPInputAuthController";
import UserContext from '../../../components/src/UserProvider';
import CustomDialog from '../../../components/src/CustomDailog.web';

const FabBox = styled(Fab)({
  width: '56px', height: '56px', padding: 0,
  fontSize: '0.875rem',minWidth: 0,boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
  boxSizing: 'border-box',minHeight: '36px',transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',fontWeight: 500,lineHeight: 1.75,borderRadius: '50%',
  letterSpacing: '0.02857em',textTransform: 'uppercase',backgroundColor: 'black'
})

const DivBox = styled(Box)({
  width: '56px',
          height: '56px',
          borderRadius: '50%',
          display: 'inline-block',
          position: 'absolute',
          background: 'linear-gradient(0deg, #3A3111 33%, #3A3111 33%, #4E4115 66%, #4E4115 66%, #F6CB38 100%)',
          boxSizing: 'border-box',
          animation: 'rotation 1s linear infinite'
})

const SpanBox = styled(Box)({content: '',boxSizing: 'border-box',position: 'absolute',left: '50%',top: '50%',transform: 'translate(-50%, -50%)',width: '44px',height: '44px',borderRadius: '50%',background: 'black'
})


export class OtpVerification extends OTPInputAuthController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    OTPInputAuthController.contextType = UserContext
    // Customizable Area End
  }


  render() {
    //  Customizable Area Start
    const { classes }: any = this.props;
    const { timer } = this.state;
    const renderLoading = () => {
      const keyframes = `
      @keyframes rotation  {
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg)}
      }
    `;
  
    return (
      <>
        <style>{keyframes}</style>
        <div style={{ position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',background: 'rgba(0, 0, 0, 0.8)',display: 'flex',justifyContent: 'center',alignItems: 'center',zIndex: 9999,
        }}>
          <FabBox
            aria-label="save"
            color="primary"
          >
            <img
              style={{
                fill: 'white', 
                width: '1.5em', height: '1.5em', 
                display: 'inline-block',
                fontSize: '1.4rem', 
                transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', userSelect: 'none', 
                flexShrink: 0, position: 'absolute', zIndex: 1, borderRadius: '9px'
              }}
              src={logo} height={24} width={24} alt="" />
          </FabBox>
          <DivBox>
            <SpanBox component={'span'}></SpanBox>
          </DivBox>
        </div>
      </>
    );
    };
    //  Customizable Area End 
    return (
        <Grid>
          {/* Customizable Area Start */}
          {this.state.loading && renderLoading()}
          <Grid item xs={12} className={classes.PPContainer}>
            <Grid container className={classes.PPGridContainer}>
              <Grid item lg={10} md={10} sm={10} xs={12} className={classes.PPRightGrid}>
              <Grid container style={{ width: 'unset' }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className={classes.PPTypo}>
                      Verify email address
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className={classes.MarginB} style={{ marginTop: "30px" }}>
                    <Box
                      className={classes.resend_otp_box_email}
                      data-test-id="gont_got_otp"
                    >
                      We’ve sent an OTP to
                      <Button
                        data-test-id="otp_sent_txt"
                        className={classes.context_email_button}
                      >{localStorage.getItem("logInEmail")}</Button>
                    </Box>
                  </Grid>
                  <Grid container className={classes.form_container} style={{width:'unset'}}>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.PPSubgrid3}>
                      <div className={classes.OptBox} style={{ marginTop: '15px' }}>
                        <div style={{ maxWidth: '456px', display: 'flex', justifyContent: 'start' }}>
                          <TextField
                            id="standard-basic"
                            data-test-id="otp1"
                            className={classes.forrmInput_textfield}
                            placeholder='-'
                            // inputProps={{min: 0, style: { textAlign: 'center' }}}
                            variant="outlined"
                            autoFocus={true}
                            type="number"
                            name="otp_code"
                            value={this.state.otp1}
                            onPaste={(event) => this.onPasteTextField(event)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeTextField(event, 'otp1', 'otp2')}
                            inputProps={{
                              className: `${classes.login_otp_input_props}`, min: 0, style: { textAlign: 'center' }
                            }}
                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                              event.target.value = this.sliceInputValue(event);
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "0px",
                                height: '100%'
                              }
                            }}
                            inputRef={this.otp1Ref}
                          />
                          <TextField
                            data-test-id="otp2"
                            id="standard-basic"
                            className={classes.forrmInput_textfield}
                            // style={{marginLeft: '15px'}}
                            variant="outlined"
                            type="number"
                            name="otp2"
                            placeholder='-'
                            value={this.state.otp2}
                            onPaste={(event) => this.onPasteTextField(event)}
                            onKeyDown={(event: any) => this.onTextFieldKeyDown(event, 'otp1', this.state.otp2)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeTextField(event, 'otp2', 'otp3')}
                            inputProps={{
                              className: `${classes.login_otp_input_props}`, min: 0, style: { textAlign: 'center' }
                            }}
                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                              event.target.value = this.sliceInputValue(event)
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "0px",
                                height: '100%'
                              }
                            }}
                            inputRef={this.otp2Ref}
                          />
                          <TextField
                            data-test-id="otp3"
                            id="standard-basic"
                            className={classes.forrmInput_textfield}
                            // style={{marginLeft: '15px'}}
                            variant="outlined"
                            type="number"
                            name="otp3"
                            placeholder='-'
                            value={this.state.otp3}
                            onPaste={(event) => this.onPasteTextField(event)}
                            onKeyDown={(event: any) => this.onTextFieldKeyDown(event, `otp2`, this.state.otp3)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeTextField(event, 'otp3', 'otp4')}
                            inputProps={{
                              className: `${classes.login_otp_input_props}`, min: 0, style: { textAlign: 'center' }
                            }}
                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                              event.target.value = this.sliceInputValue(event);
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "0px",
                                height: '100%'
                              }
                            }}
                            inputRef={this.otp3Ref}
                          />
                          <TextField
                            data-test-id="otp4"
                            id="standard-basic"
                            className={classes.forrmInput_textfield}
                            variant="outlined"
                            type="number"
                            placeholder='-'
                            name="otp4"
                            value={this.state.otp4}
                            onPaste={(event) => this.onPasteTextField(event)}
                            onKeyDown={(event: any) => this.onTextFieldKeyDown(event, `otp3`, this.state.otp4)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setStateForOtpField(event, 'otp4')}
                            inputProps={{
                              className: `${classes.login_otp_input_props}`, min: 0, style: { textAlign: 'center' }
                            }}
                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                              event.target.value = this.sliceInputValue(event);
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "0px",
                                height: '100%'
                              }
                            }}
                            inputRef={this.otp4Ref}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.MarginB} style={{ marginTop: "30px" }}>
                      <Box
                        className={classes.resend_otp_box}
                        data-test-id="gont_got_otp"
                      >
                        <Typography variant='body1' className={classes.didnt_get_code}>Didn’t get a code? Please check your junk folder or</Typography>
                        {
                          timer > 0 ?
                            <>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                  <Button
                                    data-test-id="resend_otp_send"
                                    className={classes.resend_otp}
                                    onClick={() => {
                                      !this.state.isTimerActive
                                        &&
                                        this.reSendOTP()
                                    }}
                                    style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}
                                  >
                                    Resend OTP
                                  </Button>
                                </div>
                                <div>
                                  <div style={{ whiteSpace: 'nowrap' }}>in {timer} </div>
                                </div>
                              </div>
                            </>
                            :
                            <Button
                              data-test-id="resend_otp"
                              className={classes.resend_otp}
                              onClick={() => this.reSendOTP()}
                              style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}
                            >
                              Resend OTP
                            </Button>
                        }
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.PPSubgrid4}>
                      <LightTooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        placement="top-start"
                        open={(this.state.otperror)}
                        onClose={this.handleErrorTooltipClose}
                        disableFocusListener
                        arrow
                        // disableHoverListener
                        data-test-id="submit-otp-tooltip" 
                        disableTouchListener
                        title={<>
                          <ErrorOutlineOutlinedIcon className={classes.tooltip_icon} />
                          <div className={classes.tooltip_div}> {this.state.otperrorText}</div></>}
                      >
                        <Button variant="contained" color="secondary" className={classes.PPButton}
                          data-test-id="onSubmitOtp"
                          style={{ marginTop: '15px', textTransform: 'capitalize' }}
                          onClick={(event: React.SyntheticEvent) => this.onSubmitOTP(event)}>
                          Verify OTP
                        </Button>
                      </LightTooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CustomDialog
            open={this.state.openResendOtpDialog}
            isSuccess={true}
            actions={
              [
                {
                  label: "OK",
                  handler: this.handleDialogOkBtn,
                  borderColor: "#F4CD46",
                  testId: 'check-subs-dialog-btn',
                  primary:true
                }
              ]
            }
            title="OTP Sent"
            content={`OTP has been sent to ${this.state.email}, please check your inbox.`}
          />
          {/* Customizable Area End */}
        </Grid>
    )
  }
}

export const styles: any = (theme: any) => ({

  //  Customizable Area Start
  PPContainer: {
    maxwidth: '100%',
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: '70px 20px',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '100%',
      padding: '40px 0px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
      height: '100%',
      padding: '40px 20px'
    },
    [theme.breakpoints.only('md')]: {
      width: '100%',
      height: '100%',
      padding: '50px 20px'
    },
    [theme.breakpoints.only('lg')]: {
      width: '100%',
      height: '100%',
      padding: '70px 20px'
    },
  },
  PPGridContainer: {
    width: "50%",
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    alignSelf: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '80%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '80%',
      marginLeft: 'auto'
    },
  },
  PPSideGridImg: {
    // width: "100%",
    // maxWidth: '100%',
    // height: '550px',
    backgroundColor: "transparent",
    // paddingTop: 50,
    // [theme.breakpoints.only("xs")]: {
    //   width: "100%",
    //   position: "relative",
    //   top: 70,
    //   paddingTop: 0,
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    // },
  },
  PPText: {
    // maxWidth: '80%',
    // width: 'auto',
    // filter: 'invert(1)',
    // [theme.breakpoints.only("xs")]: {
    //   width: "80%",
    // },
  },
  PPTextField: {
    // maxWidth: '100%',
    // width: "360px",
    // height: "54px",
    // borderRadius: 10,
    // alignSelf: "center",
    backgroundColor: "rgba(33, 35, 48, 1)",
    // fontFamily: "Inter",
    // fontSize: 14,

    // [theme.breakpoints.only("xs")]: {
    //   width: "60%",
    //   margin: 'auto 10%',
    // },
  },
  PPButton: {
    padding: '1px',
    borderRadius: '53px',
    border: '0.5px',
    width: '225px',
    height: "54px",
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Inter",
    fontWeight: '600',
    color: theme.palette.secondary.main,
    fontSize: '16px',
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
      background: "#F4CD46"
    },
    '@media (max-width: 601px)': {
      marginTop: '24px',
      width: '100%',
      background: '#000000',
      color: '#FFF',
      border: '1px solid #FFF',
      "&:hover": {
        background: '#000000',
        border: '1px solid #F4CD46',
      },
    }
  },
  OptBox: {
    maxWidth: '100%',
    boxSizing: 'border-box'
    // width: "100%",

    // [theme.breakpoints.only("xs")]: {
    //   width: "80%",
    //   margin: 'auto 10%',
    // },
  },
  PPTypo: {
    // maxWidth: '100%',
    // width: "80%",
    // height: 60,
    // paddingTop:'0.5%',
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: 'clamp(24px, 3vw, 32px)',
    // fontStyle: 'normal',
    fontWeight: '700',
    // lineHeight: '22px',
    letterSpacing: '-0.408px',

    [theme.breakpoints.only("sm")]: {
      fontSize: '28px'
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: '24px'
    }
  },
  PPRightGrid: {
    display: 'flex',
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      marginLeft: 0,
      // marginTop: -60,
    },
  },
  PPSubgrid1: {
    // marginBottom: 30,
    // [theme.breakpoints.only("xs")]: {
    //   marginBottom: 25,
    // }
  },
  MarginB: {
    // marginBottom: 50,
    // [theme.breakpoints.only("xs")]: {
    //   marginBottom: 30,
    // }
  },
  PPSubgrid3: {
    // marginBottom: 20,
    // [theme.breakpoints.only("xs")]: {
    //   marginBottom: 15,
    // }
  },
  PPResend: {
    // marginBottom: 30,
    // width: "100%",
    // // maxWidth:'360px',

    // [theme.breakpoints.only("xs")]: {
    //   marginBottom: 25,
    //   width: "80%",
    //   margin: 'auto 10%',
    // }
  },

  RegBack: {
    // display: 'flex',
    // flexDirection: 'row',
    // position: 'absolute',
    // top: 100,
    // left: 'auto',
    // right: 100,
    // [theme.breakpoints.only('md')]: {
    //   position: 'absolute',
    //   top: 50,
    //   left: 'auto',
    //   right: 50,
    // },
    // [theme.breakpoints.only('xs')]: {
    //   position: 'absolute',
    //   top: 20,
    //   left: 'auto',
    //   right: 30,
    // },
    // [theme.breakpoints.only('sm')]: {
    //   position: 'absolute',
    //   top: 30,
    //   left: 'auto',
    //   right: 30,
    // },
  },
  login_otp_input_props: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600,
    boxSizing: 'border-box',
    // color: theme.palette.primary.secondary,
    textAlign: 'center',
    // padding: '17px 12px',
    // height: '37px',
    background: theme.palette.primary.main,
  },
  form_container: {
    // display: 'flex',
    // maxWidth: '20%',
    // [theme.breakpoints.only("sm")]: {
    //   maxWidth: '60%',

    // },
    // [theme.breakpoints.only("xs")]: {
    //   maxWidth: '80%',
    // },
    // [theme.breakpoints.only("md")]: {
    //   maxWidth: '40%',

    // },
    // [theme.breakpoints.only("lg")]: {
    //   maxWidth: '20%',
    // },
  },
  image_direction: {
    // transform: 'scaleX(-1)',
    // margin: '0 0 0 20px'

  },
  forrmInput_textfield: {
    // width: "15%",
    // // backgroundColor: `${theme.palette.primary.main}`,
    // marginRight: "20px",
    // [theme.breakpoints.only("xs")]: {
    //   width: "23%",
    // },
    // minWidth: '40px'
    width: "70px",
    height: "70px",
    // color: theme.palette.primary.secondary,
    textAlign: 'center',
    // padding: '17px 12px',
    // height: '37px',
    background: theme.palette.primary.main,

    // [theme.breakpoints.only("xs")]: {
    //   padding: ' 8px 14px '
    // },
    [theme.breakpoints.only("md")]: {
      width: "76px",
      height: "76px"
    },
    [theme.breakpoints.only("sm")]: {
      width: "56px",
      height: "56px"
    },
    [theme.breakpoints.only("xs")]: {
      width: "56px",
      height: "56px"
    },    
    marginRight: '5%',
  },
  resend_otp_box_email: {
    color: '#ffff',
    fontFamily: 'Inter',
    fontSize: 'clamp(12px, 3vw, 16px)',
    fontWeight: 400
    // display:'flex',
    // width:'456px'
  },
  resend_otp_box: {
    color: '#ffff',
    fontFamily: 'Inter',
    display: 'flex',
    // width:'456px'
  },
  didnt_get_code: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    whiteSpace: 'nowrap',
    display: 'flex', 
    alignItems: 'center', 
    fontWeight: 300
    // [theme.breakpoints.only("xs")]: {
    //   width: "10px",
    // },
    //  width:'150px'
  },
  context_email_button: {
    color: "white",
    fontFamily: "Inter",
    fontWeight: 700,
    textTransform: 'none',
    letterSpacing: -0.40799999237060547,
    fontSize: 'clamp(12px, 3vw, 16px)'
    // lineHeight: 'normal',
  },
  resend_otp: {
    color: "white",
    fontFamily: "Inter",
    fontWeight: 700,
    textTransform: 'none',
    letterSpacing: -0.40799999237060547,
    fontSize: "16px",
    // lineHeight: 'normal',
  },
  tooltip_icon: {
    color: '#ff2A2A',
    // width: '24px',
    // height: '24px',
    // paddingRight: '15px',
    // [theme.breakpoints.only("xs")]: {
    //   width: '18px',
    //   height: '18px',
    // },
  },
  tooltip_div: {
    // whiteSpace: 'pre-line',
  }

  //  Customizable Area End
})

export default withStyles(styles)(OtpVerification);