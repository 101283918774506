import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { blobToFile, dataURLtoBlob, getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
import React from 'react';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { List, ListItem, ListItemIcon, MenuItem, Popover, Typography } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PublishIcon from '@material-ui/icons/Publish';
import imageCompression from "browser-image-compression";



export interface IAttribute {
  name: string; title: string; field_type: string; is_enable: boolean; is_required: boolean; value: string | number;
}

export interface IAttributeValues {
  name: string; value: string;
}

type FieldType = | "string" | "file" | "textarea" | "text" | "date" | "datetime" | "integer" | "number" | "boolean" | "float" | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string; field_type: FieldType;
  is_enable: boolean; is_required: boolean;
}

interface IProfileDataWeb { attributes?: { user_profile_data: { [key: string]: FieldValue } }; }

export interface IProfileData { [key: string]: FieldValue; }

export interface IProfileValues { value: string; }

interface UploadLogoSuccess {
  data: {
    id: string,
    type: string,
    attributes: {[key: string]: string | number | boolean | null}
  }
}

interface UploadLogoError {
  errors: {photo: string[]}
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  profilePicture: string;

  imageModalVisible: boolean;

  isDatePickerVisible: boolean;

  selectedImage: any;
  imagePreviewURL: any;
  isUpdateProfile: boolean;
  imageURL: any;

  isError: boolean;
  isValid: boolean;

  formData: {
    [key: string]: any;
  };
  selectedData: any;
  screenWidth: any;
  allBranches: any;
  isEdit: boolean;
  logInToken: string;

  errors: {
    [key: string]: string;
  };
  anchorEl: any,
  isCropping: boolean,
  startDate: any;
  businessData: any;
  allBuisnesses: any;
  loading: boolean;
  isBusinessProfileCreated: boolean;
  ImageError: boolean;
  duplicateName: boolean;
  isBusinessOwner: boolean,
  imgUrl:string,
  largeImageError: boolean,
  uploadImageErrorMsg: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateNewBusinessController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getIndustryCallId: string = "";
  createNewBusinessApiCall: string = "";
  getAllBusinessApiCallId: string = ""
  fileInputRef: any;
  uploadProfilePictureAPICallId: string = "";
  getAllIndustriesListApiId: string = "";
  editBusinessProfileApiCallId: string = ""
  removeProfilePictureAPICallId: string = "";
  getProfileDetailsApiId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      profilePicture: "",

      imageModalVisible: false,

      isDatePickerVisible: false,

      selectedImage: '',
      imageURL: '',

      isValid: false,
      isError: false,
      errors: {},

      formData: {

        business_name: "",
        address: "",
        industry: "",
        company_number: "",
        business_type: "",
        number_of_branches: "",
        number_of_employees: "",
        expected_number_of_subscribers: "",

      },
      selectedData: null,
      screenWidth: window.innerWidth,
      logInToken: "",
      allBranches: [],
      imagePreviewURL: '',
      isEdit: false,
      anchorEl: null,
      isCropping: false,
      isUpdateProfile: false,
      startDate: "",
      businessData: null,
      allBuisnesses: null,
      loading: false,
      isBusinessProfileCreated: false,
      ImageError: false,
      duplicateName: false,
      isBusinessOwner: false,
      imgUrl:"",
      largeImageError: false,
      uploadImageErrorMsg: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    window.addEventListener('resize', this.handleResize);
    const logInRes = await getStorageData('logInRes', true);
    const otpConfRes = await getStorageData('otpConfRes', true);
    const userLoggedIn = await getStorageData("userLoggedIn", true);
    this.setTokenInStorage(token, logInRes, otpConfRes);
    window.addEventListener('resize', this.handleResize);
    this.checkUserLoggedIn(userLoggedIn, logInRes, otpConfRes);
    // Customizable Area End
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  setTokenInStorage = (token: any, logInRes: any, otpConfRes: any) => {

    if (token) {
      window.localStorage.clear();
      const logRes = { logInToken: token };
      const otpRes = { token: token }

      setStorageData("logInRes", JSON.stringify(logRes));
      setStorageData("otpConfRes", JSON.stringify(otpRes));
      removeStorageData("userLoggedIn");
      removeStorageData("isProfileCreated");
      removeStorageData("isLogin");
      this.setState({ logInToken: token });
      this.fetchUserProfileData(token)
      this.fetchAllIndustriesList(token)
      this.getAllBusinessApiListData(token)
    } else {
      this.stateSetAfterSignup(otpConfRes);
      this.setStateAfterLogIn(logInRes);
    }
  }

  setStateAfterLogIn = (logInRes: any) => {
    if (logInRes) {
      this.setState({
        logInToken: logInRes?.logInToken
      })
      this.fetchUserProfileData(logInRes?.logInToken);
      this.fetchAllIndustriesList(logInRes?.logInToken)
      this.getAllBusinessApiListData(logInRes?.logInToken)
    }
  }


  stateSetAfterSignup = (otpConfRes: any) => {
    if (otpConfRes) {
      this.setState({ logInToken: otpConfRes?.token });
      this.fetchUserProfileData(otpConfRes?.token);
      this.fetchAllIndustriesList(otpConfRes?.token)
      this.getAllBusinessApiListData(otpConfRes?.token)
    }
  }



  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.getAllIndustriesListApiId) {
        this.handleIndustryApiRes(responseJson)
      }
      if (apiRequestCallId === this.getAllBusinessApiCallId) {
        this.getAllBusinessResApi(responseJson)
      }
      if (apiRequestCallId === this.editBusinessProfileApiCallId) {
        this.editBusinessApiRes(responseJson)
      }
      if (apiRequestCallId === this.createNewBusinessApiCall) {
        this.createNewBusinessApiRes(responseJson)
      }
      if (apiRequestCallId === this.getProfileDetailsApiId) {
        this.handleUserProfileApiRes(responseJson)
      }
      if (apiRequestCallId === this.uploadProfilePictureAPICallId) {
        this.handleUploadLogoApiRes(responseJson)
      }

      runEngine.debugLog("Message Recived", message);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleChooseFile = () => {
    this.fileInputRef.current.click();
  };


  handleUpload = (url: any) => {
    if (!url)
      return;

    const blob = dataURLtoBlob(url);
    const fileData = blobToFile(blob, 'filename.png');
    if (fileData && this.state.isEdit) {
      this.setState({loading: true});
      const formData = new FormData();
      formData.append('photo', fileData);
      this.uploadProfilePictureAPICallId = sendAPIRequest(
        `account_block/business/update_bussiness_logo/${this.state.selectedData?.id}`,
        {
          method: configJSON.httpPutMethod,
          body: formData,
          headers: {
            "token": this.state.logInToken
          }
        },
      );
    }
  }

  handleCropImage = (response: any) => {
    const { error, url } = response;
    if (!error) {
      this.setState({ imagePreviewURL: url, imageURL: '',imgUrl:url });
      this.handleUpload(url);
      this.setOpen();
    } else {
      this.setOpen();
    }
  };

  setOpen = () => { this.setState({ isCropping: false, anchorEl: null }); };

  handleImageChange = async(event: any) => {
    const image = event.target.files[0];
    if (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif|heif|heic)$/i.test(image?.name) && event.target.files && event.target.files.length > 0) {
      this.setState({ ImageError: false });
      const file = event.target.files[0];
      const options = {
        maxSizeMB: 0.75,
        maxWidthOrHeight:
          1300,
        useWebWorker: true,
      };
  
      const compressedImg = await imageCompression(file, options);
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({ imageURL: event.target?.result as string, anchorEl: null, isCropping: true });
      }; 
      reader.readAsDataURL(compressedImg);
    } else { this.setState({ ImageError: true }) }
  };

  ImageErrorDialog = () => { 
    this.setState({ ImageError: false }) 
  }
  ErrorDialog = () => { 
    this.setState({ duplicateName: false }) 
  }
  largeImageErrorDialog = () => { 
    this.setState({ largeImageError: false }) 
  }

  onHandleChange = (e: any) => {
    const { name, value } = e?.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: value ? '' : prevState.errors[name],
      },
    }));
  };


  isAlphaNumeric = (input: any) => {
    // Define a regex pattern for a 10-digit US phone number
    const pattern = /^[a-zA-Z0-9]*$/;
    return pattern.test(input);
  };


  onHandleChangeDate = (value: any) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ['date_of_birth']: value,
      },
    }));
  };

  createNewBusiness = (blob:any) =>{
    this.setState({loading:true})
    const originUrl = window.location.origin;
    const fileData = blob === '' ? '' : blobToFile(blob, 'filename.png');
    const newformdata = new FormData()
    newformdata.append('business[business_name]',this.state.formData.business_name)
    newformdata.append('business[address]',this.state.formData.address)
    newformdata.append('business[company_number]',this.state.formData.company_number)
    newformdata.append('business[industry]',this.state.formData.industry)
    newformdata.append('business[business_type]',this.state.formData.business_type)
    newformdata.append('business[number_of_branches]',this.state.formData.number_of_branches)
    newformdata.append('business[number_of_employees]',this.state.formData.number_of_employees)
    newformdata.append('business[expected_number_of_subscribers]',this.state.formData.expected_number_of_subscribers)
    newformdata.append('base_url',originUrl)
    // newformdata.append('business[photo]',fileData)
    if (fileData !== '') {
      newformdata.append('business[photo]', fileData);
    }
      const headers = {
        token: this.state.logInToken,
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createNewBusinessApiCall = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/business`
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        newformdata
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  
     }

  handleSubmit = (event: any) => {
    event.preventDefault();

    if (Object.keys(this.validateForm()).length === 0 || undefined) {
      const blob = dataURLtoBlob(this.state.imgUrl)
      this.createNewBusiness(blob);
    } else {
      this.setState({ errors: this.validateForm()});
    }
  };

  handleEditSubmit = (event: any) => {
    event.preventDefault();
    if (Object.keys(this.validateForm()).length === 0 || undefined) {
      this.editBusinessProfile();
    } else {
      this.setState({ errors: this.validateForm() });
    }
  };

  validateForm = () => {
    this.setState({ isValid: true });
    const { formData, businessData } = this.state;
    const errors: { [key: string]: string } = {};

    const fieldsToCheck = [
      { field: 'business_name', label: 'Business Name' },
      { field: 'address', label: 'Address' },
      { field: 'company_number', label: 'Company Number' },
      { field: 'industry', label: 'Industry' },
      { field: 'business_type', label: 'Business Type' },
      { field: 'number_of_branches', label: 'Number of Branches' },
      { field: 'number_of_employees', label: 'Number of Employees' },
      { field: 'expected_number_of_subscribers', label: 'Expected Number of Subscribers' }
    ];

    for (const fieldObj of fieldsToCheck) {
      const { field } = fieldObj;
      if(field != "company_number"){
        if (this.checkIsValid(formData[field] || businessData?.[field])) {
          this.setState({ isValid: false });
          errors[field] = `Required field is missing`;
        }
      }
    }

    return errors;
  };



  checkIsValid = (field: any) => {
    return (field === null || field === undefined || field.trim() === '')
  }


  openInfoPage() {
    this.send(getNavigationMessage("VerifyIdentity", this.props,));
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  fetchAllIndustriesList = async (token: any) => {
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllIndustriesListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIndustriesApiEndPOint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllIndustriesListApiRes = (response: any) => {
    this.setState({ allBranches: response?.data })

  };

  getAllBusinessApiListData = async (token: any) => {
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    this.setState({ loading: true }); const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)); this.getAllBusinessApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllBusinessApiEndPoint
    ); requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllBusinessApiRes = (response: any) => {
    this.setState({ allBuisnesses: response?.data })
  };
  getLastEntry = () => {
    if (!this.state.allBuisnesses) {
      return this.setState({ allBuisnesses: [], loading: false })
    }
    const data = this.state.allBuisnesses
    return data;
  };


  handleEditBusinesnessProfileBtn = () => {
    const lastEntry = this.getLastEntry();
    this.getLastEntry()
    if (this.state.isBusinessOwner) {
      this.setState({
        isEdit: true,
        businessData: lastEntry?.attributes
      })
    }
  };


  handleInputChange = (event: any) => {

    const { name, value } = event?.target;

    this.setState((prevState) => ({
      businessData: {
        ...prevState.businessData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: value ? '' : prevState.errors[name],
      },
    }));

  };

  editBusinessProfile = () => {
    const bodyData = {
      business_name: this.state.businessData?.business_name,
      address: this.state.businessData?.address,
      company_number: this.state.businessData?.company_number,
      industry: this.state.businessData?.industry,
      business_type: this.state.businessData?.business_type,
      number_of_branches: this.state.businessData?.number_of_branches,
      number_of_employees: this.state.businessData?.number_of_employees,
      expected_number_of_subscribers: this.state.businessData?.expected_number_of_subscribers,
    }

    if (Object.keys(this.validateForm()).length === 0 || undefined) {
      this.editBusinessProfileApiCallId = sendAPIRequest(
        `account_block/business/${this.state.selectedData?.id}`,
        {
          method: configJSON.httpPutMethod,
          body: bodyData,
          headers: {
            "Content-Type": "application/json",
            "token": this.state.logInToken
          }
        },
      );
    } else {
      this.setState({ errors: this.validateForm() });
    }

  }

  cancelEditProfile = () => {
    this.setState({ isEdit: false })
  }

  removeProfilePhoto = () => {
    const formData = new FormData();
    formData.append('photo', '');
    this.setState({ imagePreviewURL: "", anchorEl: null })
    this.removeProfilePictureAPICallId = sendAPIRequest(
      `account_block/business/remove_bussiness_logo/${this.state.selectedData?.id}`,
      {
        method: configJSON.httpPutMethod,
        body: formData,
        headers: {
          "token": this.state.logInToken
        }
      },
    );
  }

  bottomPostion = () => {
    const bottomMedia = this.state.screenWidth <= 1279 ? '0px' : '220px';
    return bottomMedia
  }

  widthSize = () => {
    const { screenWidth } = this.state;
    if (screenWidth <= 601) {
      return '150px'
    } else if (screenWidth <= 775) {
      return '250px'
    } else {
      return '300px'
    }
  }
  heightSize = () => {
    const { screenWidth } = this.state;
    if (screenWidth <= 601) {
      return '150px'
    } else if (screenWidth <= 775) {
      return '250px'
    } else {
      return '300px'
    }
  }

  centerPostion = () => {
    const centerMedia = this.state.screenWidth <= 1279 ? 'center' : 'space-between';
    return centerMedia
  }

  handleIndustryApiRes = (response: any) => {
    this.handleAllIndustriesListApiRes(response);
    this.setState({ loading: false })
  }

  editBusinessApiRes = (responseJson: any) => {
    if (responseJson.data) {
      this.getAllBusinessApiListData(this.state.logInToken)
      this.setState({ isEdit: false, loading: false })
    }
    this.setState({ loading: false })
    if (responseJson.errors) {
      responseJson.errors === "Business name This name is already taken" && this.setState({ duplicateName: true })
    }
  }

  createNewBusinessApiRes = (responseJson: any) => {

    this.setState({ loading: false })
    if (responseJson?.data) {
      this.openInfoPage()
      this.setState({ loading: false })
    }
    if (responseJson?.errors) {
      this.setState({ loading: false });
      responseJson.errors[0].business_name && this.setState({ duplicateName: true });
      responseJson.errors[0].photo && this.setState({ largeImageError: true, uploadImageErrorMsg: `Photo ${responseJson.errors[0].photo}` })
    }
  }

  getAllBusinessResApi = (responseJson: any) => {
  
    if (responseJson?.data?.attributes?.photo) {
      const imageUrl = responseJson?.data?.attributes?.photo
      this.setState({ imagePreviewURL: imageUrl })
    }
    this.handleAllBusinessApiRes(responseJson);
    this.setState({ loading: false, selectedData: responseJson?.data })
  }

  getIndustryMenuItem = () => {
    return Array.isArray(this.state.allBranches) && this.state.allBranches.flatMap((option) => {
      const branchName = option?.attributes?.industry_subcategory;
      return branchName.map((subcategory: [number, string]) => (
        <MenuItem key={subcategory[0]} value={subcategory[1]}>
          {subcategory[1]}
        </MenuItem>
      ));
    });
  }

  imageRender = (webStyle: any) => {
    const widthSize = this.widthSize()
    const heightSize = this.heightSize()
    const { isEdit, imagePreviewURL } = this.state
    if (isEdit) {
      return (
        <>
          <div style={{ ...webStyle.ImgUpload, width: widthSize, height: heightSize }}>
            {imagePreviewURL && <div data-test-id='image-upload-div-selected' style={{width: '100%', height: '100%'}}>
            <img id="profile_image"
              className="image-priview" key={new Date().getTime()} src={imagePreviewURL} alt="Preview" style={{ width: '100%', height: '100%', borderRadius: '50%' }} /></div>}
            {!imagePreviewURL ?
              <div
                onClick={this.handleChooseFile} style={{ display: 'block', textAlign: 'center' }}
              > <button id="handleChooseFile" style={{ border: "none" }}> </button> <Typography className="upload-profile-photo" style={webStyle.uploadProfilePhoto}>Logo</Typography> </div> : ""
            }
          </div>
          <Popover open={!!this.state.anchorEl} anchorEl={this.state.anchorEl}
            onClose={this.handleClose} anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }} transformOrigin={{
              vertical: 'top', horizontal: 'left',
            }}
          >
            <List component="nav" aria-labelledby="nested-list-subheader" style={{ padding: "8px", flexShrink: 0, borderRadius: "8px", width: "192px", height: "82px", display: "flex", flexDirection: "column", justifyContent: "space-evenly", boxSizing: "border-box" }}>
              <ListItem button style={{ padding: 0, margin: 0, height: "36px" }} onClick={this.handleChooseFile}> <ListItemIcon style={{ minWidth: "30px" }}> <PublishIcon /> </ListItemIcon>
                <Typography style={{ padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black" }}>Upload New Photo</Typography> </ListItem>
              <ListItem button style={{ padding: 0, margin: 0, height: "36px" }} onClick={this.removeProfilePhoto}>
                <ListItemIcon style={{ minWidth: "30px" }}> <DeleteOutlineIcon /> </ListItemIcon> <Typography style={{ padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black" }}>Remove Photo</Typography></ListItem> </List> </Popover>

        </>
      )
    } else {
      return (
        <>
          <div style={{ ...webStyle.ImgUpload, width: widthSize, height: heightSize }}>{imagePreviewURL && <div data-test-id='image-upload-div-selected' >  <img id="profile_image" className="image-priview" key={new Date().getTime()} src={imagePreviewURL} alt="Preview" style={{ width: '100%', height: '100%', borderRadius: '50%' }} /></div>}
            {!imagePreviewURL ?
              <div
                style={{ display: 'block', textAlign: 'center' }}
              > <button id="handleChooseFile" style={{ border: "none" }}> </button> <Typography className="upload-profile-photo" style={webStyle.uploadProfilePhoto}>Logo</Typography> </div> : ""
            }
          </div>
        </>
      )
    }
  }

  handleJoinBusiness = () => {
    setStorageData("isJoiningBusiness", true);
    this.send(getNavigationMessage("QrCodes", this.props));
  }

  goToCreateNewBusiness = () => {
    removeStorageData("isJoiningBusiness");
    this.send(getNavigationMessage("CreateNewBusiness", this.props));
  }

  checkUserLoggedIn = (user: any, logInRes: any, otpConfRes: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (user && window.location.pathname === "/SetupBusiness") {
      this.send(getNavigationMessage("LandingPage", this.props));
    } else if (!logInRes && !otpConfRes && !token) {
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
      window.localStorage.clear();
    }

    if (token) {
      const logRes = { logInToken: token };
      const otpRes = { token: token }
      localStorage.setItem('logInRes', JSON.stringify(logRes));
      localStorage.setItem('otpConfRes', JSON.stringify(otpRes));
      this.setState({ logInToken: token });
    }
  }

  fetchUserProfileData = async (token: any) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProfileDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserProfileApiRes = (responseJson: any) => {
    this.setState({ loading: false })
    if (!responseJson.errors) {
      const { attributes } = responseJson?.data;
      this.setState({ isBusinessOwner: attributes.account?.business_owner })
    }
  }

  handleUploadLogoApiRes = (response: UploadLogoError) => {
    this.setState({loading: false});
    if(response.errors && response.errors.photo?.length>0){
      const {errors} = response;
      this.setState({largeImageError: true, uploadImageErrorMsg: `Photo ${errors.photo[0]}` })
    }
  }
  // Customizable Area End
}
