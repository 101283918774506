import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  labelOr: string;
  signupText: string;
  agreeTCtext: string;
  tcText: string;
  privacyPolicyText: string;
  emailValidation: boolean;
  validatePasswordField: boolean;
  checkButton: boolean;
  passwordTooltip: boolean;
  isValidLength: boolean;
  hasUppercase: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
  isHovering: boolean;
  hasLowerCase: boolean;
  passwordFieldSatisfied: boolean;
  toggleButton: boolean;
  tAndCCheckBox: boolean;
  allValidate: boolean;
  isValidEmail: boolean;
  iSValidPassword: boolean;
  loading: boolean;
  popupValidation: boolean;
  isTermsAndConditionBoxChecked: boolean;
  isEmailRegistered: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  reSendOTPAPICallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelTitle: string = "";
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",

      labelOr: configJSON.labelOr,
      signupText: configJSON.singUp,
      agreeTCtext: configJSON.agreeTCtext,
      emailValidation: false,
      validatePasswordField: false,
      privacyPolicyText: configJSON.privacyPolicyText,
      tcText: configJSON.tcText,
      toggleButton: false,
      checkButton: false,
      passwordTooltip: false,
      isValidLength: false,
      hasUppercase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSymbol: false,
      passwordFieldSatisfied: true,
      isHovering: false,
      tAndCCheckBox: false,
      allValidate: false,
      isValidEmail: false,
      popupValidation: false,
      iSValidPassword: false,
      loading: false,
      isTermsAndConditionBoxChecked: false,
      isEmailRegistered: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.emailReg = (/\S+@\S+\.\S+/);
    this.labelTitle = configJSON.labelTitle;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.handleValidationApiRes(responseJson);

        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.handleCreateAccountApiRes(responseJson);
        } else if (apiRequestCallId === this.reSendOTPAPICallId) {
          this.handleReSendOtpApiRes(responseJson);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const userLoggedIn = await getStorageData("userLoggedIn", true);
    this.checkUserLoggedIn(userLoggedIn);
  }

  checkUserLoggedIn = (user: any) => {
    if(user){
      this.send(getNavigationMessage("LandingPage", this.props));
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleCloseDialog = () => {
    this.setState({
      popupValidation: false
    })
  }

  handleValidationApiRes = (response: any) => {
    this.arrayholder = response.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  goToForgotPasswordOTP = () => {

    this.setState({loading:true});

    let body = {
      data: {
        attributes: {
          email: this.state.email
        }
      }
    }

    this.reSendOTPAPICallId = sendAPIRequest(
      `account_block/resend_otp`,
      {
        method: "POST",
        body: { ...body },
        headers: {
          "Content-Type": "application/json"
        }
      },
    );
  }

  navigateToOtpPage = () => {
    this.send(getNavigationMessage("OTPInputAuth", this.props));
  }

  handleReSendOtpApiRes = (response: any) => {
    this.setState({loading: false});
    const { meta } = response;

    if(meta){
      setStorageData("signUpToken", meta.token);
      setStorageData("logInEmail", this.state.email);
      this.navigateToOtpPage();
    }
  }

  setUserTypeStorage = () => {
    window.localStorage.setItem('isSubscriber', JSON.stringify(this.state.toggleButton));
  }
  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  validatePassword = (password: string) => {
    const regexLength = /.{8,}/;
    const regexUppercase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexNumber = /\d/;
    const regexSymbol = /[!@#$%^&*(),.?":{}|<>]/;

    this.setState({
      isValidLength: regexLength.test(password),
      hasUppercase: regexUppercase.test(password),
      hasNumber: regexNumber.test(password),
      hasSymbol: regexSymbol.test(password),
      hasLowerCase: regexLowerCase.test(password),
    });

    if (this.state.isValidLength && this.state.hasLowerCase && this.state.hasUppercase && this.state.hasNumber && this.state.hasSymbol && !this.isStringNullOrBlank(this.state.password)) {
      this.setState({ iSValidPassword: true })
    }
    else {
      this.setState({ iSValidPassword: false })
    }
  };



  handleClickCheckButton = () => {
    this.setState({
      checkButton: !this.state.checkButton
    }, () => {
      setTimeout(() => {
        this.state.checkButton && this.setState({ tAndCCheckBox: false });
      }, 200);
    });
  };

  handlePasswordTooltip = () => {
    this.setState({
      passwordTooltip: !this.state.passwordTooltip,
      passwordFieldSatisfied: true
    });
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      toggleButton: !prevState.toggleButton,
    }));
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setPassword = (textstring: string) => {
    this.setState({
      password: textstring,
      passwordFieldSatisfied: true
    });
    this.validatePassword(textstring)
  };

  goToTermsAndCondition() {
    this.send(getNavigationMessage("TermsConditions", this.props));
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
    this.setState({ emailValidation: false })
    if (this.emailReg.test(text) && !this.isStringNullOrBlank(this.state.email)) {
      this.setState({ isValidEmail: true })
    }
    else {
      this.setState({ isValidEmail: false })
    }
  };

  createAccount(): boolean {

    this.setState({ loading: true, isEmailRegistered: false });
    if (!this.checkValidations()) {
      this.setState({ loading: false });
      return false;
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
      role_name: this.state.toggleButton ? 'subscriber' : 'business user'
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkValidations = () => {
    let isValid = true
    if (!this.isValidEmail(this.state.email) || this.isStringNullOrBlank(this.state.email)) {
      this.setState({ emailValidation: true });
      isValid = false;
    }

    if (!this.passwordReg.test(this.state.password) || this.isStringNullOrBlank(this.state.password)) {
      this.setState({ passwordFieldSatisfied: false });
      isValid = false;
    }

    if (!this.state.hasLowerCase || !this.state.hasNumber || !this.state.hasSymbol || !this.state.hasUppercase || !this.state.isValidLength) {
      this.setState({ passwordFieldSatisfied: false });
      isValid = false;
    }

    if (!this.state.checkButton) {
      this.setState({ tAndCCheckBox: true })
      isValid = false;
    }

    return isValid;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }


  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;


  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  btnColorSignUp = () => {
    return this.state.isValidEmail && this.state.iSValidPassword && this.state.isTermsAndConditionBoxChecked ? '#f4cd46' : '#fff';
  }

  handleCreateAccountApiRes = (responseJson: any) => {
    this.setState({ loading: false });
    let errors: { [key: string]: string } = {};
    if (!responseJson.errors) {
      this.setState({ isEmailRegistered: false });
      setStorageData("signUpToken",responseJson.meta.token);
      setStorageData("logInEmail", this.state.email)
      this.setUserTypeStorage();
      this.send(getNavigationMessage("OTPInputAuth", this.props));
    } else if (responseJson.errors && responseJson.errors[0].account == "account not activated") {
      this.setState({ popupValidation: true });
      setStorageData("logInEmail", this.state.email)
    } else {
      errors.email = "Email is already registered";
      this.setState({ emailValidation: true, isEmailRegistered: true });
    }
  }

  // Customizable Area End
}
