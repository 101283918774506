import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  CardContent, Avatar, Container, Tooltip, withStyles, styled, createTheme, ThemeProvider
  // Customizable Area End
} from "@material-ui/core";
import CustomeButton from "../../../components/src/CustomeButton.web";
// Customizable Area Start
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '50px 0',
        background: '#212226'
      },
    },
    MuiBackdrop: {
      'root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    },
  }
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '14px',
    fontFamily: 'inter',
    padding: theme.spacing(2),
    backgroundColor: '#212226', // Custom background color
    opacity: 1,
  },
}))(Tooltip);

const SubsDetails = styled(Box)({
  display: 'flex',
  gap: '25px',
  paddingLeft: "clamp(5px, 150px, 10%)",
  paddingTop: '10px',
  flexWrap: 'wrap',
  paddingBottom: '10px',
  paddingRight: '10px',
  "@media(max-width: 700px)": {
    justifyContent: 'center'
  }
});

const Card = styled(Box)({
  backgroundColor: '#000',
  color: 'white',
  border: '1px solid #262525',
  textAlign: 'center' as const,
  width: '373px',
  height: '256px',
  boxSizing: 'border-box' as const,
  "@media(max-width: 875px)": {
    width: '350px',
    height: '225px',
  },
  "@media(max-width: 825px)": {
    width: '300px',
    height: '220px',
  },
  "@media(max-width: 700px)": {
    width: '280px',
    height: '210px',
  }
})

// Customizable Area End

import QrCodesController, {
  Props, configJSON,
} from "./QrCodesController";
import CustomDialog from "../../../components/src/CustomDailog.web";

export default class QrSubscriptionList extends QrCodesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const a = this.state.branchName.reduce((acc: any, item: any, index: any, array: any) => {
      const isLastItem = index === array.length - 1;
      const element = <span key={index}>{item}{!isLastItem && ', '}</span>;
      acc.push(element);
      return acc;
    }, []);
    return (
      // Customizable Area Start
      <>
        {!this.state.openDetails &&

          <Box>
            {this.state.loading && <SpinnerLoader />}

            {(!this.state.subsciptionDatas) ?
              <Box style={{ fontSize: 'clamp(10px, 16px, 2vw)' }}>
                <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', ...webStyle.wholeContent }}>
                  <ArrowBackIcon style={{ color: '#fff', cursor: 'pointer', fontSize: '2em' }} onClick={this.handleGoToQrPage} id="newback" />
                </Box>
                <div style={{ color: 'white', fontSize: '1.5em', fontFamily: 'inter', fontWeight: 200, marginLeft: '40%', marginTop: '80px' }}>
                  {configJSON.noSubscriptionText}
                </div>
              </Box>
              :

              <Box style={{ fontSize: 'clamp(10px, 16px, 2vw)' }} >
                <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', ...webStyle.wholeContent }}>
                  <ArrowBackIcon style={{ color: '#fff', cursor: 'pointer', fontSize: '2em' }} onClick={this.handleGoToQrPage} data-test-id="arrow-back-icon-btn" />
                  <Avatar style={{ cursor: 'pointer' }} alt="Profile" src={this.returnBusinessLogoUrl()}/>
                  <Typography style={webStyle.heading}>{this.state.bussinessData?.business_name}</Typography>
                </Box>


                <SubsDetails>
                  {this.state.subsciptionDatas?.map((list: any, i: any) => {
                    return (
                      <Card key={i}>
                        <CardContent style={webStyle.cardContentStyle}>
                          <Typography style={webStyle.title} gutterBottom>
                            {list.name}
                          </Typography>
                          <Typography style={webStyle.premium} gutterBottom>
                            {list.number_of_units ? list.number_of_units :'Unlimited'} {list.name_of_units} per  {list.per}
                          </Typography>
                          <Typography style={{ ...webStyle.description }} color="textSecondary">
                            <span style={{ fontWeight: 500 }}>&#163;{list.price}</span>/{"month"}
                          </Typography>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CustomeButton id="view-details" text="View Details" style={{ ...webStyle.btn, textTransform: 'none' }} onclicked={() => { this.viewDetailsOpening(list) }} />
                          </Box>
                        </CardContent>
                      </Card>
                    )
                  })}

                </SubsDetails>
              </Box>
              // dummy commit view details functionality is create on other  feature
            }
          </Box>
        }
         {this.state.loading && <SpinnerLoader />}
        {this.state.openDetails &&
          <Box className="subscription-details-container-main" style={{ boxSizing: 'border-box', fontSize: 'clamp(10px, 16px, 2vw)' }}>
            <Container maxWidth="lg">
              <Box style={{ display: 'flex', marginBottom: 'clamp(1.5rem, 4rem, 4vw)', gap: '20px', alignItems: 'center' }}>
                <ArrowBackIcon id="backtosubscription" onClick={this.handleBackToSubscriptionList} style={{ fontSize: '2em', color: '#fff', cursor: 'pointer' }} />
                <Avatar style={{ cursor: 'pointer' }} alt="Profile" src={this.returnBusinessLogoUrl()} />
                <Typography style={webstyless.headingStyle}>{this.returnBusinessName()}</Typography>
              </Box>
            </Container>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 'clamp(1rem, 3rem, 2vw)' }}>
              <Box>
                <Typography style={{ color: '#fff', fontSize: '2.5em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px', textTransform: 'capitalize' }}>{`${this.state.subscriptionProfileData?.name}`}</Typography>
              </Box>
              <Box>
                <Typography style={{ color: '#fff', fontSize: '1.8em', fontWeight: 200, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>
                  <span style={{ color: '#fff', fontSize: '1.5em', fontWeight: 700, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>&#163;{`${this.state.subscriptionProfileData?.price}/`}</span>
                  {`month`} </Typography>
              </Box>
              <Box style={{ width: '100%' }}>
                <Typography style={{ fontWeight: 600, color: '#fff', fontSize: '1.5em', fontFamily: 'Inter, sans-serif', letterSpacing: '-0.72px', marginBottom: '15px', textAlign: 'center' }}>{configJSON.aboutPlanText}</Typography>
                <Typography style={{ fontFamily: 'Inter, sans-serif', color: '#fff', fontSize: '1.25em', fontWeight: 200, letterSpacing: '-0.408px', textAlign: 'center', padding: '0 19%' }}>{this.state.subscriptionProfileData?.description}</Typography>
              </Box>
            </Box>
            <Box className="subscription-description-container" style={{ ...webstyless.subscriptionDescriptionContainerStyle, flexDirection: 'row' }}>
              <Box>
                <Typography style={webstyless.descHeadingStyle}>{configJSON.whatYouGetText}</Typography>
                <Typography style={webstyless.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.number_of_units != 0 ? this.state.subscriptionProfileData?.number_of_units : "Unlimited"} ${this.state.subscriptionProfileData?.name_of_units} per ${this.state.subscriptionProfileData?.per}`}</Typography>
              </Box>
              <Box>
                <Typography style={webstyless.descHeadingStyle}>{configJSON.durationText}</Typography>
                <Typography style={webstyless.descHeadingValueStyle}>
                  {this.state.subscriptionProfileData?.duration == 0 ? 'Unlimited' : `${this.state.subscriptionProfileData?.duration} months`}
                </Typography>
              </Box>
              <Box>
                <Box>

                  <Typography style={webstyless.descHeadingStyle}>{configJSON.pausesText}</Typography>
                  <Typography style={webstyless.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.pauses_allowed}`}</Typography>
                </Box>
              </Box>
              <Box>


                <Box>
                  <Typography style={webstyless.descHeadingStyle}>{configJSON.validBranchesText}</Typography>

                  <CustomTooltip title={a}
                    arrow
                    PopperProps={{
                      popperOptions: {
                        modifiers: {
                          offset: {
                            offset: '20, -15',
                          },
                        },
                      },
                    }}

                    style={webstyless.tooltipStyle}>
                    <Typography style={webstyless.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.valid_branches}`}</Typography>
                  </CustomTooltip>

                </Box>
              </Box>
            </Box>
            <Box className="subscription-buttons-container" style={{ display: 'flex', justifyContent: 'center', padding: '10px', gap: '20px', flexWrap: 'wrap', alignItems: 'center', }}>
              <CustomeButton 
                text="Buy now" 
                data-test-id="subscription-detail-edit-btn" 
                primary={true} 
                onclicked={this.buyNowSubscriptionApi} 
                style={{ ...webstyless.buttonCommonStyle, color: '#fff', border: '1px solid #F4CD46' }} 
              />
            </Box>
          </Box>
        }
        <ThemeProvider theme={theme}>
          <CustomDialog
            isError={true}
            open={this.state.openBuySubscriptionErrorDialog}
            actions={[
              {
                label: "OK",
                handler: this.handleBuysubscriptionErrorDialogBtn,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary: true
              },
            ]}
            content={this.state.buySubscriptionErrorMsg}
            title="Failed to purchase"
          />
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  wholeContent: {
    paddingLeft: "clamp(5px, 150px, 10%)",
    paddingTop: '10px'
  },
  btngrp: {
    paddingLeft: 'clamp(30px, 185px, 5vw)',
    paddingTop: 'clamp(15px, 30px, 5vw)'
  },
  heading: {
    color: 'white',
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: '2em',
  },

  cardss: {
    paddingLeft: '10px'
  },
  card: {
    backgroundColor: '#000',
    color: 'white',
    border: '1px solid #262525',
    textAlign: 'center' as const,
    width: '373px',
    height: '256px',
    marginLeft: '10px'
  },

  btnstyle: {
    color: 'white',
    border: '0.5px solid white',
    fontSize: '1em',
    fontWeight: 600,
    fontFamily: 'Inter',
    margin: '17px',
    width: '225px',
    height: '56px',
    borderRadius: '53px',

  },
  premium: {
    fontFamily: 'Inter',
    fontSize: '1em',
    fontWeight: 500,
  },
  description: {
    color: 'white',
    marginTop: '8px',
    marginBottom: '8px',
    fontSize: '1.5em',
    fontWeight: 200,
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 700,
    marginTop: '12px',
  },

  units: {
    color: 'white',
    marginLeft: 'clamp(30px, 290px, 5vw)',
  },
  btn: {
    color: 'white',
    border: '1px solid white',
    width: "112px",
    height: '40px',
    borderRadius: '70px',
    padding: '5px 8px 5px 8px',
    fontSize: '14px',
    fontFamilty: 'inter',
    fontWeight: 600,
  },
  cardContentStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box' as const
  }
}
const webstyless = {

  tooltipStyle: {
    width: "198px",
    height: "73.2px"
  },

  headingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '2em',
    fontWeight: 600,
    letterSpacing: '-0.72px',
  },

  descHeadingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
  },
  viewDetails: {
    color: 'white',
    border: '1px solid #F4CD46',
    width: "112px",
    height: '40px',
    borderRadius: '70px',
    padding: '5px 8px 5px 8px',
    fontSize: '0.9em',
  },
  buttonCommonStyle: {
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '225px',
    height: '56px',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1empx',
    textTransform: 'capitalize' as 'capitalize',
    color: '#fff',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '60px',
    background: '#000'
  },
  subscriptionDescriptionContainerStyle: {
    display: 'flex',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItem: 'center',
    gap: '1rem 10%',
    flexWrap: 'wrap' as 'wrap',
    padding: 'clamp(1.5rem, 4rem, 4vw) 4vw',
  },
  descHeadingValueStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 200,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
  },
}
// Customizable Area End
